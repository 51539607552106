import { getLocalizedDateTime, isToday, TimeZone } from "@marathon/common/timeZoneHelper";
import { DateTime } from "luxon";
import { MobileServiceFeeConfiguration } from "./Configuration";
import { Pet, PetService } from "./Pet";
import { SelectedService } from "../api/SelectedService";
import { Service } from "./Service";
import { Discount, DiscountData, DiscountType } from "./Discount";
import { Groomer } from "./Groomer";
import { Credit } from "./Credit";
import { Hub } from "./Hub";
import { SecondaryAddress } from "./SecondaryAddress";
import { AppointmentOrOccurrence, Occurrence } from "./Occurrence";
import { GroomerException } from "./GroomerException";
import LocalDate from "../LocalDate";
import { getRepeatLabel } from "../textHelper";
import { getMobileServiceFeePrice } from "../mobileServiceFeeHelper";
import { Customer } from "./Customer";
import { formatMediumDateWithOrdinal, formatTime, formatTimeWindow } from "../timeFormatHelper";

const HOURS_IN_A_DAY = 24;

interface AppointmentSelectedPet {
    petId: string,
    serviceId: string,
    servicePrice: number,
    breedId: string,
    customServiceTime?: number,
    petWeight: number
}

interface ExtraItem {
    description: string,
    duration: number,
    price: number
}

interface AppointmentCredit {
    id: string,
    value: number,
    customer_referred: string,
    customer_referred_id: string,
    was_used: boolean,
}

interface AppointmentData {
    customer: {
        id: string,
        name: string,
        city: string,
        area?: string,
        phone: string,
        email: string,
        notes: string,
        is_from_signup: boolean,
        drive_time_to_hub?: number,
        secondary_address_id?: string
    },
    notes?: string,
    notes_from_customer?: string,
    groomer: {
        id: string,
        name: string,
        phone: string,
        email: string,
        hub_id: string
    },
    selected_pets: AppointmentSelectedPet[],
    extra_items?: ExtraItem[],
    created_at: Date,
    start_time: Date,
    end_time: Date,
    expiration_time?: Date,
    status: AppointmentStatus,
    booked_by: string,
    frequency?: {
        type: string,
        interval: number
    },
    transaction?: {
        date?: Date,
        square_id?: string,
        error_code?: string
    },
    tip?: number,
    imported?: boolean,
    recurrence_id?: string,
    exception_created_at?: Date,
    updated_by?: string,
    updated_at?: Date,
    on_my_way_notification_time?: Date,
    minutes_until_arrival?: number,
    arrival_time?: Date,
    grooming_complete_notification_time?: Date,
    service_start_time?: Date,
    service_end_time?: Date,
    expiration_task?: string,
    upcoming_booked_by?: string,
    is_new_customer?: true,
    lapsed_customer_reminder_sent_days?: number[],
    is_from_sms?: boolean,
    drive_time?: AppointmentDriveTime,
    groomer_tracking_start_day_task?: string,
    groomer_tracking_appointment_start_task?: string,
    groomer_tracking_appointment_duration_task?: string,
    cancellation?: {
        reason: string,
        customer_notified?: true,
        collected_fee?: number
    },
    attributed_to?: string
    is_from_online_booking?: boolean,
    has_image_from_groomer?: true,
    discounts?: DiscountData[],
    credits?: AppointmentCredit[],
    time_zone: TimeZone,
    is_from_chatbot?: true,
    invitation_code?: string,
    pet_image_url?: string,
    grooming_notes?: string,
    payment_status?: PaymentStatus,
    show_exact_time?: true,
    suggestion_appointment?: {
        id: string,
        status: AppointmentStatus.pending | AppointmentStatus.declined | AppointmentStatus.expired
    },
    suggestion_for_appointment_id?: string,
    origin?: string,
    unconfirmed?: {
        by: string,
        at: Date
    }
}

interface AppointmentDriveTime {
    duration_min: number,
    origin_recurrence_id?: string
}

interface Appointment extends AppointmentData {
    id: string
}

interface ArrivalTimeWithLabelOptions {
    includeWindowNote?: boolean,
    includePreposition?: boolean,
    capitalize?: boolean
}

export const ARRIVAL_WINDOW_HOURS = 2;
export const MIDDAY_HOUR = 12;

class Appointment {
    constructor(id: string, data: AppointmentData) {
        this.id = id;
        Object.assign(this, data);
    }
    toData(): AppointmentData {
        const { id, ...data } = this;
        return data;
    }
    toSuggestionData(): AppointmentData {
        const { id, suggestion_appointment, ...data } = this;
        return data;
    }
    hasNotifiedMinutesUntilArrival() {
        return !!this.minutes_until_arrival;
    }
    hasGroomerArrived() {
        return !!this.arrival_time;
    }
    hasBeenStarted() {
        return !!this.service_start_time;
    }
    hasBeenFinished() {
        return !!this.service_end_time || !!this.transaction?.date;
    }
    isCheckedIn(checkOutExtensionMinutes?: number) {
        const initialTime = this.arrival_time ?? this.service_start_time;
        const checkoutTime = this.service_end_time || this.transaction?.date;
        const hasCheckedOut = checkoutTime
            ? DateTime.fromJSDate(checkoutTime).plus({ minutes: checkOutExtensionMinutes ?? 0 }).toMillis() < DateTime.now().toMillis()
            : false;
        return (
            !!initialTime &&
            isToday(initialTime, this.time_zone) &&
            !hasCheckedOut &&
            (!!checkOutExtensionMinutes || this.status === AppointmentStatus.scheduled)
        );
    }
    isConsideredPaid() {
        return (
            this.payment_status === PaymentStatus.paid ||
            this.payment_status === PaymentStatus.awaiting_payment ||
            this.payment_status === PaymentStatus.offline_payment
        );
    }
    finalPrice() {
        return Appointment.getFinalPriceFromData(this);
    }
    static getGrossPrice(
        selectedPets: { servicePrice: number }[],
        extraItems?: { price: number }[]
    ) {
        const selectedPetsPrice = selectedPets.reduce((accumulated, x) => accumulated + x.servicePrice, 0);
        const extraItemsPrice = extraItems?.reduce((accumulated, x) => accumulated + x.price, 0) ?? 0;
        return selectedPetsPrice + extraItemsPrice;
    }
    static getGrossPriceFromData(data: AppointmentData) {
        return this.getGrossPrice(data.selected_pets, data.extra_items);
    }
    static getFinalPrice(
        selectedPets: { servicePrice: number }[],
        extraItems?: { price: number }[],
        discounts?: DiscountData[],
        credits?: AppointmentCredit[]
    ) {
        const grossPrice = this.getGrossPrice(selectedPets, extraItems);
        const priceWithCreditDiscount = credits
            ? Credit.apply(credits, grossPrice)
            : grossPrice;
        const finalPrice = discounts
            ? Discount.apply(discounts, priceWithCreditDiscount)
            : priceWithCreditDiscount;
        return finalPrice;
    }
    static getFinalPriceFromData(data: AppointmentData) {
        return this.getFinalPrice(
            data.selected_pets,
            data.extra_items,
            data.discounts,
            data.credits
        );
    }
    static sumFinalPrices(appointments: AppointmentOrOccurrence[]) {
        return appointments.reduce(
            (accumulated, value) => accumulated + Appointment.getFinalPriceFromData(value), 0
        );
    }
    static activeStatuses() {
        return [
            AppointmentStatus.scheduled,
            AppointmentStatus.completed
        ];
    }
    static inactiveStatuses() {
        return [
            AppointmentStatus.cancelled,
            AppointmentStatus.expired,
            AppointmentStatus.declined
        ];
    }
    static calendarStatuses() {
        return [
            AppointmentStatus.scheduled,
            AppointmentStatus.completed,
            AppointmentStatus.pending,
            AppointmentStatus.unconfirmed
        ];
    }
    static mobileServiceFeeToExtraItem(fee: MobileServiceFeeConfiguration, customer: Customer | null) {
        return {
            description: fee.description,
            duration: fee.duration,
            price: getMobileServiceFeePrice(fee, customer)
        };
    }
    get statusIn() {
        return {
            activeStatuses: Appointment.activeStatuses().includes(this.status),
            inactiveStatuses: Appointment.inactiveStatuses().includes(this.status),
            calendarStatuses: Appointment.calendarStatuses().includes(this.status)
        };
    }
    static getSelectedPets(assignments: SelectedService[], pets: Pet[]) {
        return assignments
            .map(assignment => {

                const pet = pets.find(x => x.id === assignment.petId);
                if (!pet)
                    throw new Error(`Pet not valid ${assignment.petId}`);

                const petService = pet.services.find(x => x.id === assignment.serviceId);
                if (!petService)
                    throw new Error(`Service not valid ${assignment.serviceId}`);

                return this.getSelectedPet(pet, petService);
            });
    }
    static getSelectedPet(pet: Pet, service: PetService): AppointmentSelectedPet {
        if (!pet.breed_id)
            throw new Error("Can't book appointments for default dogs anymore");

        return ({
            petId: pet.id,
            serviceId: service.id,
            servicePrice: service.price,
            breedId: pet.breed_id,
            petWeight: pet.weight
        });
    }
    static get uniquePets() {
        const getUniquePets =
            (appointment: AppointmentOrOccurrence) =>
                appointment.selected_pets.filter((x, i, a) => a.findIndex(y => y.petId === x.petId) === i);
        return {
            weight: (appointment: AppointmentOrOccurrence) => getUniquePets(appointment).reduce((acc, x) => acc + (x.petWeight ?? 0), 0),
            length: (appointment: AppointmentOrOccurrence) => getUniquePets(appointment).length,
        };
    }
    static getDuration(
        services: Service[],
        selectedPets: { serviceId: string | null, customServiceTime?: number }[],
        extraItems?: { duration: number }[],
        extraTime?: number
    ) {
        const extraItemsTime = extraItems?.reduce((accumulated, x) => accumulated + x.duration, 0) ?? 0;

        const selectedPetsTime = selectedPets.reduce((accumulated, pet) => {
            accumulated = +accumulated || 0;
            const service = services.find(x => x.id === pet.serviceId);
            if (service) {
                const toAccumulate = service.allow_time_override && pet.customServiceTime !== undefined
                    ? pet.customServiceTime
                    : service.estimated_time;
                return accumulated + toAccumulate;
            }
            else {
                return accumulated;
            }
        }, extraTime ?? 0);

        return extraItemsTime + selectedPetsTime;
    }
    static isCancelable(appointment: AppointmentOrOccurrence, check24Hours?: true) {
        const isWithin24Hours = DateTime.fromJSDate(appointment.start_time)
            .diffNow("hours").hours < HOURS_IN_A_DAY;

        const hasCancelableStatus = Appointment.calendarStatuses().includes(appointment.status);
        const startsInTheFuture = appointment.start_time > new Date();
        const isTimeConstraintSatisfied = check24Hours ? !isWithin24Hours : true;

        return hasCancelableStatus && startsInTheFuture && isTimeConstraintSatisfied;
    }
    static isConfirmable(appointment: AppointmentOrOccurrence) {
        return appointment.status === AppointmentStatus.pending;
    }
    static isModifiable(appointment: AppointmentOrOccurrence) {
        return this.isCancelable(appointment, true);
    }
    static getRevenuePerHour(
        groomers: Groomer[],
        appointments: AppointmentOrOccurrence[],
        groomerExceptions: GroomerException[],
        date: LocalDate) {
        const totalRevenue = Appointment.sumFinalPrices(appointments);
        const workingHours = groomers.reduce((accumulated, current) => accumulated + current.getWorkingHoursForDay(date, groomerExceptions), 0);
        return workingHours > 0 ? Math.round(totalRevenue / workingHours) : 0;
    }
    static isNewCustomer(appointment: AppointmentData | Occurrence) {
        if (appointment instanceof Occurrence) {
            return appointment.isFirstOccurrenceOfNewRecurrentCustomer();
        }
        else {
            return !!appointment.is_new_customer;
        }
    }
    static getChangesInformation(oldData: AppointmentData | undefined, newData: AppointmentData) {
        const isBeingException = !oldData && newData.recurrence_id !== undefined;
        const isBeingScheduled = !isBeingException && oldData?.status !== AppointmentStatus.scheduled && newData.status === AppointmentStatus.scheduled;
        const groomerChanged = !!oldData && oldData.groomer.id !== newData.groomer.id;
        const dateChanged = !!oldData && oldData.start_time.getTime() !== newData.start_time.getTime();
        const wasScheduled = !!oldData && oldData.status === AppointmentStatus.scheduled;
        const isBeingCancelled = (wasScheduled || isBeingException) && newData.status === AppointmentStatus.cancelled;
        const isBeingCheckedOut = (wasScheduled || isBeingException) && newData.status === AppointmentStatus.completed;
        const isExceptionChange = isBeingException && !isBeingCancelled;
        const addressChanged = this.addressHasChanged(oldData, newData);
        const isBeingOnHold = [AppointmentStatus.pending, AppointmentStatus.expired, AppointmentStatus.declined].includes(newData.status);
        return {
            isBeingScheduled,
            isBeingCancelled,
            isBeingCheckedOut,
            isBeingException,
            groomerChanged,
            dateChanged,
            isExceptionChange,
            addressChanged,
            isBeingOnHold
        };
    }
    static getDriveTimeToHubInMin(appointment: AppointmentOrOccurrence) {
        return appointment.customer.drive_time_to_hub && Math.round(appointment.customer.drive_time_to_hub / 60);
    }
    static getDogsCountLabel(appointment: AppointmentData) {
        return (
            appointment.selected_pets.length > 1
                ? `${appointment.selected_pets.length} dogs`
                : "1 dog"
        );
    }
    static getSummaryForZapier(appointment: AppointmentData) {
        const pacificDate = getLocalizedDateTime(appointment.start_time, appointment.time_zone);
        return `${appointment.customer.name} ${pacificDate.toFormat("D")} @ ${pacificDate.toFormat("t")} for ${Appointment.getDogsCountLabel(appointment)} ($${Appointment.getFinalPriceFromData(appointment)}) ${appointment.frequency ? getRepeatLabel(appointment.frequency) : ""}`;
    }
    static getSummaryForZapierCancellationNotification(appointment: AppointmentData, hasCompletedAnyAppointments: boolean, hub?: Hub | null, secondaryAddress?: SecondaryAddress) {
        const date = getLocalizedDateTime(appointment.start_time, appointment.time_zone);
        const durationMinutes = DateTime.fromJSDate(appointment.end_time).diff(DateTime.fromJSDate(appointment.start_time), "minutes").minutes;
        const segments = [
            hub?.acronym || "No hub",
            `Stylist: ${appointment.groomer.name}`,
            `${date.toFormat("yyyy-MM-dd hh:mm a")}, ${durationMinutes} min, ${secondaryAddress?.city || appointment.customer.city}`,
            appointment.customer.name,
            `Reason: ${appointment.cancellation?.reason || "No reason provided"}`,
            `Attributed to: ${appointment.attributed_to || "No user provided"}`,
            `Total revenue: $${Appointment.getFinalPriceFromData(appointment)}`,
            `New customer: ${hasCompletedAnyAppointments ? "No" : "Yes"}`
        ];
        return segments.join(" - ");
    }
    static getDiscountText(type: DiscountType) {
        if (type === DiscountType.firstOccurrence) return "First groom";
        else if (type === DiscountType.referral) return "Referral";
        else if (type === DiscountType.manual) return "Manual discount";
        else if (type === DiscountType.firstTimeCustomer) return "First time customer";
        else if (type === DiscountType.mobileServiceFee) return "Mobile service fee";
        else if (type === DiscountType.code) return "Promo code";
        else throw new Error(`Invalid discount type ${type}`);
    }
    static addressHasChanged(oldData: AppointmentData | undefined, newData: AppointmentData) {
        const oldSecondaryAddress = oldData?.customer.secondary_address_id;
        const newSecondaryAddress = newData.customer.secondary_address_id;

        const mainAddressChangeToSecondaryAddress = !oldSecondaryAddress && !!newSecondaryAddress;
        const secondaryAddressChangeToMainAddress = !!oldSecondaryAddress && !newSecondaryAddress;
        const secondaryAddressesChangeToAnother = oldSecondaryAddress !== newSecondaryAddress;

        return mainAddressChangeToSecondaryAddress || secondaryAddressChangeToMainAddress || secondaryAddressesChangeToAnother;
    }
    static getStartTimeExtendedData(appointment: AppointmentData) {
        const localDate = LocalDate.forContextTimeZone(appointment.start_time, appointment.time_zone);
        return ({
            date: appointment.start_time,
            month: localDate.month,
            day: localDate.day
        });
    }
    static getArrivalTime(appointment: AppointmentData) {
        if (appointment.show_exact_time) {
            return formatTime(appointment.start_time, appointment.time_zone);
        }
        else {
            return formatTimeWindow(
                appointment.start_time,
                DateTime.fromJSDate(appointment.start_time).plus({ hours: ARRIVAL_WINDOW_HOURS }).toJSDate(),
                appointment.time_zone
            );
        }
    }
    static getArrivalTimeWithLabel(appointment: AppointmentData, options: ArrivalTimeWithLabelOptions = { includeWindowNote: false, includePreposition: true, capitalize: false }) {
        const { includeWindowNote, includePreposition, capitalize } = options;
        const arrivalTime = Appointment.getArrivalTime(appointment);
        let arrivalTimeText = "";

        if (appointment.show_exact_time) {
            arrivalTimeText = includePreposition ? `at ${arrivalTime}` : arrivalTime;
        }
        else {
            const betweenWindow = includePreposition
                ? `between ${arrivalTime}`
                : arrivalTime;
            arrivalTimeText = includeWindowNote ? `for an arrival window ${betweenWindow}` : betweenWindow;
        }

        return capitalize ? arrivalTimeText.charAt(0).toUpperCase() + arrivalTimeText.slice(1) : arrivalTimeText;
    }
    static getDateWithArrivalTime(appointment: AppointmentData) {
        return `${formatMediumDateWithOrdinal(appointment.start_time, appointment.time_zone)} ${Appointment.getArrivalTimeWithLabel(appointment, { includeWindowNote: true, includePreposition: true })}`;
    }
    static getRealDuration(appointment: AppointmentOrOccurrence) {
        const checkinDate = appointment.service_start_time;
        const checkoutDate = appointment.service_end_time || appointment.transaction?.date;

        return (
            !checkinDate || !checkoutDate
                ? 0
                : DateTime.fromJSDate(checkoutDate).diff(DateTime.fromJSDate(checkinDate), "minutes").minutes
        );
    }
    static isToday(startTime: Date, timeZone: TimeZone) {
        return isToday(startTime, timeZone);
    }
    static isValidSuggestionsDateFilter(specificDate: LocalDate, isFromFunctions?: true) {
        const referenceDate = isFromFunctions
            ? LocalDate.forContextTimeZone(new Date(), specificDate.contextTimeZone)
            : LocalDate.forSystemTimeZone(new Date(), specificDate.contextTimeZone);
        return !specificDate.precedesOrEqual(referenceDate);
    }
    static getSuggestionDuration(
        allServices: Service[],
        suggestionServiceIds: string[],
        customer: { skip_mobile_service_fee?: boolean },
        mobileServiceFee: MobileServiceFeeConfiguration | null
    ) {
        const extraTime =
            !customer.skip_mobile_service_fee && mobileServiceFee?.is_enabled
                ? mobileServiceFee.duration
                : 0;
        return Service.getServicesDuration(allServices, suggestionServiceIds) + extraTime;
    }
    static get errorMessages() {
        return {
            specificDateNotValid: "Suggestions specific date parameter not valid (must be in the future)"
        };
    }
    static get systemCancellationReasons() {
        return {
            requestedViaChatbot: "Client requested cancellation via chatbot",
            suggestionAccepted: "Client accepted different time suggestion",
            rescheduled: "Appointment rescheduled"
        };
    }
    static get updateData() {
        return {
            forCheckoutCallback: (succeeded: boolean, transactionId?: string, errorCode?: string): Partial<AppointmentData> => {
                return (
                    succeeded
                        ? {
                            payment_status: PaymentStatus.paid,
                            transaction: {
                                date: new Date(),
                                square_id: transactionId
                            }
                        }
                        : {
                            transaction: {
                                error_code: errorCode
                            }
                        }
                );
            },
            forCompletion: (): Partial<AppointmentData> => {
                return ({
                    status: AppointmentStatus.completed,
                    service_end_time: new Date()
                });
            },
            forUnpaidCompletion: (): Partial<AppointmentData> => {
                return ({
                    status: AppointmentStatus.completed,
                    payment_status: PaymentStatus.awaiting_payment
                });
            }
        };
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static fromApi(serialized: any) {
        const { id, ...data } = serialized;
        return new Appointment(id, data);
    }
}

enum AppointmentStatus {
    scheduled = "scheduled",
    completed = "completed",
    cancelled = "cancelled",
    pending = "pending",
    expired = "expired",
    declined = "declined",
    unconfirmed = "unconfirmed"
}

enum PaymentStatus {
    paid = "paid",
    awaiting_payment = "awaiting_payment",
    offline_payment = "offline_payment",
    bad_debt = "bad_debt"
}

enum AppointmentOrigin {
    ExistingCustomerInboundCall = "Existing Customer Inbound Call",
    ExistingCustomerOutboundCall = "Existing Customer Outbound Call",
    ExistingCustomerOutboundTextAutoHunted = "Existing Customer Outbound Text (auto-hunted)",
    ExistingCustomerOutboundTextLapsedMsg = "Existing Customer Outbound Text (lapsed msg)",
    ExistingCustomerOutboundTextMarketingMsg = "Existing Customer Outbound Text (marketing msg)",
    ExistingCustomerInboundText = "Existing Customer Inbound Text",
    ExistingCustomerOutboundText = "Existing Customer Outbound Text",
    ExistingCustomerBookedByGroomer = "Existing Customer Booked by Groomer",
    ExistingCustomerBookedOnline = "Existing Customer Booked Online",
    NewCustomerInboundCall = "New Customer Inbound Call",
    NewCustomerOutboundCall = "New Customer Outbound Call",
    NewCustomerBookedOnline = "New Customer Booked Online",
    NewCustomerOutboundText = "New Customer Outbound Text",
    NewCustomerInboundText = "New Customer Inbound Text",
}

export { Appointment, AppointmentStatus, PaymentStatus, AppointmentOrigin };
export type { AppointmentData, AppointmentSelectedPet, AppointmentCredit, AppointmentDriveTime, ExtraItem };