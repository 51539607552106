import { useState, useEffect, useCallback } from "react";
import CallableFunctions from "@marathon/client-side/utilities/CallableFunctions";
import { BookingSuggestion } from "@marathon/common/api/BookingSuggestion";
import { TimeFilterOptionKey, timeFilterOptions } from "@marathon/common/constants";
import * as Sentry from "@sentry/react";
import LocalDate from "@marathon/common/LocalDate";
import { CustomerAddressData } from "@marathon/common/entities/Customer";
import { SecondaryAddress } from "@marathon/common/entities/SecondaryAddress";

interface Props {
    customerId: string,
    selectedServices: { petId: string, serviceId: string }[],
    specificDate?: LocalDate,
    selectedGroomerId?: string,
    selectedTimeFilter?: TimeFilterOptionKey,
    selectedAddress: CustomerAddressData | SecondaryAddress,
    isAuthenticated: boolean
}

export function useFetchSuggestions({
    customerId,
    selectedServices,
    specificDate,
    selectedGroomerId,
    selectedTimeFilter,
    selectedAddress,
    isAuthenticated
}: Props) {

    const [suggestions, setSuggestions] = useState<BookingSuggestion[]>([]);
    const [isFetching, setIsFetching] = useState(false);

    const latestFetchDateState = useState<Date | null>(null);
    const setLatestFetchDate = latestFetchDateState[1];

    const getServiceIds = useCallback(() => {
        return selectedServices.map(x => x.serviceId);
    }, [selectedServices]);

    useEffect(() => {

        const fetchSuggestions = async () => {
            const serviceIds = getServiceIds();
            if (!serviceIds.length)
                return;

            try {
                const fetchDate = new Date();
                setLatestFetchDate(fetchDate);

                setIsFetching(true);
                const timeRange = selectedTimeFilter ? timeFilterOptions[selectedTimeFilter] : undefined;
                const fetchedSuggestions = await CallableFunctions.current.public.getBookingSuggestions(customerId, serviceIds, selectedAddress, specificDate, selectedGroomerId, timeRange);
                setLatestFetchDate(previousValue => {
                    if (previousValue?.getTime() === fetchDate.getTime()) {
                        setSuggestions(fetchedSuggestions);
                        setIsFetching(false);
                        return null;
                    }
                    else {
                        return previousValue;
                    }
                });
            }
            catch (error) {
                setIsFetching(false);
                Sentry.captureException(error);
            }
        };

        if (isAuthenticated) {
            fetchSuggestions();
        }

    }, [customerId, getServiceIds, specificDate, selectedGroomerId, selectedTimeFilter, selectedAddress, setLatestFetchDate, isAuthenticated]);

    return { suggestions, isFetchingSuggestions: isFetching };
}
