import TagManager from "react-gtm-module";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Theme } from "@mui/material/styles";
import setupVersionAutoReload from "./helpers/versionHelper";
import LocalStorage from "./utilities/LocalStorage";
import "@marathon/common/arrayExtensions";
import { FirestoreService } from "./utilities/FirestoreService";
import { FunctionService } from "./utilities/FunctionService";
import { container } from "tsyringe";
import { INJECTED_FIRESTORE_SERVICE_TOKEN } from "@marathon/client-side/repositories/IFirestoreService";
import { INJECTED_FUNCTION_SERVICE_TOKEN } from "@marathon/client-side/utilities/IFunctionService";
declare module "@mui/styles/defaultTheme" {
    interface DefaultTheme extends Theme { }
}
setupVersionAutoReload();

LocalStorage.hydrateCache();

container.register(INJECTED_FIRESTORE_SERVICE_TOKEN, { useClass: FirestoreService });
container.register(INJECTED_FUNCTION_SERVICE_TOKEN, { useClass: FunctionService });

if (process.env.REACT_APP_SENTRY_DSN && process.env.REACT_APP_SENTRY_ENVIRONMENT) {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 0.1,
        release: "barkbus-marathon@" + process.env.REACT_APP_CLIENT_VERSION
    });
}

if (process.env.REACT_APP_GOOGLE_GTM_ID) {
    const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GOOGLE_GTM_ID
    };

    TagManager.initialize(tagManagerArgs);
}