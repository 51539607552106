import { monthsRangeList } from "@marathon/common/constants";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";

interface PetAgeSelectProps {
    onChange: (age: number) => void
    age?: number
    isAdmin: boolean
    size?: "small" | "medium"
    error?: boolean
    helperText?: string
}

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 300
        }
    },
};

export const PetAgeSelect = ({ age, onChange, isAdmin, size, error, helperText }: PetAgeSelectProps) => {
    return (
        <FormControl
            variant="outlined"
            size={size}
            fullWidth
            error={error}>
            {!isAdmin && <InputLabel id="age">Age *</InputLabel>}
            <Select
                label={isAdmin ? undefined : "Age *"}
                labelId="age"
                name="age"
                MenuProps={MenuProps}
                onChange={e => onChange(parseFloat(e.target.value))}
                value={age ? age.toString() : ""}
                style={{ textAlign: "initial" }}
                defaultValue=""
            >
                {monthsRangeList.map((v, idx) => <MenuItem key={idx} value={v.avr_in_years}>{v.text}</MenuItem>)}
                <MenuItem value={1}>1 Year</MenuItem>
                {[2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19].map((v, idx) => <MenuItem key={idx} value={v}>{`${v} Years`}</MenuItem>)}
                <MenuItem value={20}>20+ Years</MenuItem>
            </Select>
            {error && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
};