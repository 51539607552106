import { RRule } from "rrule";
import { Recurrence, WEEKLY_FREQUENCY_TYPE } from "./entities/Recurrence";
import LocalDate from "./LocalDate";
import { getDateWithMergedTime } from "./timeZoneHelper";

export function getOccurrencesForDate(recurrences: Recurrence[], date: LocalDate) {
    const todayMatches = [];

    for (let index = 0; index < recurrences.length; index++) {
        const recurrence = recurrences[index];
        const frequency = recurrence.getFrequency();

        const rule = new RRule({
            freq: getRuleFrequency(frequency.type),
            interval: frequency.interval || 1,
            dtstart: recurrence.start_time
        });

        const nextOccurrenceDate = rule.after(date.toDayStart(), true);
        if (!nextOccurrenceDate)
            continue;

        if (!date.includesDate(nextOccurrenceDate))
            continue;

        const occurrenceIndex = getOccurenceIndex(nextOccurrenceDate, rule);
        if (recurrence.exceptions && recurrence.exceptions.includes(occurrenceIndex))
            continue;

        todayMatches.push(
            getOccurrence(recurrence, nextOccurrenceDate, occurrenceIndex)
        );
    }
    return todayMatches;
}

export function getFutureOccurrences(recurrence: Recurrence, limit: number, fromDate?: Date, ignoreExceptions?: true) {
    const rule = getRule(recurrence);

    const datesWithIndexes: { date: Date, index: number }[] = [];
    rule.all(function (date, index) {
        if (date.getTime() > (fromDate?.getTime() ?? new Date().getTime()) && (!!ignoreExceptions || !recurrence.exceptions || !recurrence.exceptions.includes(index))) {
            datesWithIndexes.push({ date, index });
        }
        return datesWithIndexes.length < limit;
    });

    return datesWithIndexes.map(x =>
        getOccurrence(
            recurrence,
            x.date,
            x.index
        )
    );
}

export function getPastOccurrences(recurrence: Recurrence, last: number) {
    const rule = getRule(recurrence);

    const datesWithIndexes: { date: Date, index: number }[] = [];
    rule.all(function (date, index) {
        if (date.getTime() < new Date().getTime() && (!recurrence.exceptions || !recurrence.exceptions.includes(index))) {
            datesWithIndexes.push({ date, index });
        }

        return date.getTime() < new Date().getTime();
    });

    return datesWithIndexes.slice(-last).map(x =>
        getOccurrence(
            recurrence,
            x.date,
            x.index
        )
    );
}

export function isDateAnOccurrenceOf(dateToCheck: Date, recurrence: Recurrence) {
    const rule = getRule(recurrence);

    if (recurrence.start_time > dateToCheck)
        return false;

    const dates: Date[] = [];
    rule.all(function (date) {
        dates.push(date);
        return date.getTime() <= dateToCheck.getTime();
    });

    return dates.some(x => x.getTime() === dateToCheck.getTime());
}

const getRule = (recurrence: Recurrence) => {
    const frequency = recurrence.getFrequency();
    return new RRule({
        freq: getRuleFrequency(frequency.type),
        interval: frequency.interval || 1,
        dtstart: recurrence.start_time
    });
};

export function getOccurrenceFromIndex(recurrence: Recurrence, occurrenceIndex: number) {
    const frequency = recurrence.getFrequency();
    const rule = new RRule({
        freq: getRuleFrequency(frequency.type),
        interval: frequency.interval || 1,
        dtstart: recurrence.start_time
    });
    const occurrencesUpToIndex = rule.all((_, idx) => idx <= occurrenceIndex);
    const occurrenceDate = occurrencesUpToIndex[occurrenceIndex];
    return getOccurrence(recurrence, occurrenceDate, occurrenceIndex);
}

export function getNextOccurrenceDate(recurrence: Recurrence) {
    const frequency = recurrence.getFrequency();

    const rule = new RRule({
        freq: getRuleFrequency(frequency.type),
        interval: frequency.interval || 1,
        dtstart: recurrence.start_time
    });
    const activeOccurrenceDates: Date[] = [];
    rule.all(function (date, index) {
        if (date.getTime() > new Date().getTime() && (!recurrence.exceptions || !recurrence.exceptions.includes(index))) {
            activeOccurrenceDates.push(date);
        }
        return activeOccurrenceDates.length < 1;
    });
    return activeOccurrenceDates[0];
}

const getRuleFrequency = function (frequencyType: string) {
    if (frequencyType === "DAILY")
        return RRule.DAILY;
    else if (frequencyType === WEEKLY_FREQUENCY_TYPE)
        return RRule.WEEKLY;
    else if (frequencyType === "MONTHLY")
        return RRule.MONTHLY;
    else
        throw new Error(`Frequency type not valid: ${frequencyType}`);
};

const getOccurenceIndex = (occurrenceDate: Date, rule: RRule) => {
    const previousOccurrences = rule.all((x) => x.getTime() < occurrenceDate.getTime());
    return previousOccurrences.length;
};

const getOccurrence = (recurrence: Recurrence, occurrenceDate: Date, occurrenceIndex: number) => {
    const difference =
        recurrence.end_time.getTime() -
        recurrence.start_time.getTime();

    //HACK: We explicitly set the time to honor the original occurrence (effectively ignoring the DST shift).
    const startTime = getDateWithMergedTime(occurrenceDate, recurrence.start_time, recurrence.time_zone);

    return recurrence.toOccurrence(
        occurrenceIndex,
        startTime,
        new Date(startTime.getTime() + difference)
    );
};
