import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import useStyles from "@marathon/web/components/onlineBooking/styles";
import { useHistory } from "react-router-dom";
import { OnlineBookingContentWrapper } from "../OnlineBookingContentWrapper";
import steps from "@marathon/common/onlineBookingSteps";
import { TitleWrapper, ContentWrapper } from "../shared/CommonWrappers";

interface LeadExistingProps {
    existingPhone: string,
    title: string,
    subtitle?: string,
    discountCode?: string
}

export default function LeadExisting({ existingPhone, title, subtitle, discountCode }: LeadExistingProps) {
    const classes = useStyles();
    const history = useHistory();

    return (
        <OnlineBookingContentWrapper>
            <TitleWrapper title={title} subtitle={subtitle} />
            <ContentWrapper>
                <Button
                    type="submit"
                    variant="contained"
                    className={classes.button}
                    disableElevation
                    onClick={() => history.push(`/online-booking/${steps.returning.welcome}?phone=${existingPhone}${discountCode ? `&discountCode=${discountCode}` : ""}`)}
                >
                    Continue booking
                </Button>
                <div style={{ marginTop: 20, fontSize: 23, color: "#323232" }}>Need Help?</div>
                <div style={{ marginTop: 20, marginBottom: 20, fontSize: 26, color: "#323232" }}>Call or text us at 1 (800) 742-9255</div>
                <Link to="mailto:info@barkbus.com" style={{ fontSize: 18, color: "#323232" }}>
                    Email us: info@barkbus.com
                </Link>
            </ContentWrapper>
        </OnlineBookingContentWrapper>
    );
}