import { isPointInPolygon, isPointWithinRadius, getDistance } from "geolib";
import { Hub } from "./entities/Hub";
import { CustomerAddressData } from "./entities/Customer";
import { SecondaryAddress } from "./entities/SecondaryAddress";

interface Coordinates {
    lat: number
    lng: number
}

export const isWithinServiceArea = (coordinates: Coordinates, hubs: Hub[]) => {
    return hubs.some(hub => isWithinServiceAreaForHub(coordinates, hub));
};

export const isWithinServiceAreaForHub = (coordinates?: Coordinates, hub?: Hub) => {
    if (!coordinates || !hub)
        return false;
    return isPointInPolygon(coordinates, hub.vertices);
};

export const isWithinRadius = (fromCoordinates: Coordinates, toCoordinates: Coordinates, radius: number) => {
    return isPointWithinRadius(fromCoordinates, toCoordinates, radius);
};

export const getDistanceInMeters = (fromCoordinates: Coordinates, toCoordinates: Coordinates, accuracy = 1) => {
    return getDistance(fromCoordinates, toCoordinates, accuracy);
};

export const getDistanceInMiles = (fromCoordinates: Coordinates, toCoordinates: Coordinates, accuracy = 1) => {
    const distance = getDistanceInMeters(fromCoordinates, toCoordinates, accuracy);
    return distance / 1609.34;
};

export type ZipCondition = "in" | "out" | "partial";
export const getZipCondition = (dots: Coordinates[], hubs: Hub[]): ZipCondition => {
    const dotHubs: Hub[] = [];
    dots.forEach(dot => {
        hubs.forEach(hub => {
            if (isWithinServiceAreaForHub(dot, hub)) {
                dotHubs.push(hub);
                return;
            }
        });
    });

    if (dotHubs.length === 0) {
        return "out";
    }
    else if (dotHubs.length === 3 && dotHubs.every(x => x.id === dotHubs[0].id)) {
        return "in";
    }
    else {
        return "partial";
    }
};

interface ProximityGroup {
    location: Coordinates,
    count: number
}

export const findMostCrowdedLocation = (coordinates: Coordinates[], proximityThreshold: number) => {
    const proximityGroups: Record<string, ProximityGroup> = {};

    for (const coordinate of coordinates) {
        let foundGroup = false;

        for (const groupKey in proximityGroups) {
            const groupLocation = proximityGroups[groupKey as keyof ProximityGroup].location;

            const distance = getDistanceInMeters(coordinate, groupLocation);

            if (distance <= proximityThreshold) {
                proximityGroups[groupKey].count++;
                foundGroup = true;
                break;
            }
        }

        if (!foundGroup) {
            const groupKey = `${coordinate.lat},${coordinate.lng}`;
            proximityGroups[groupKey] = { location: { lat: coordinate.lat, lng: coordinate.lng }, count: 1 };
        }
    }

    let maxCount = 0;
    let mostCrowdedLocation;
    for (const groupKey in proximityGroups) {
        if (proximityGroups[groupKey].count > maxCount) {
            mostCrowdedLocation = proximityGroups[groupKey].location;
            maxCount = proximityGroups[groupKey].count;
        }
    }

    return mostCrowdedLocation;
};

export const getGeoLocations = (customerAddress: CustomerAddressData, secondaryAddresses: SecondaryAddress[]) => {
    if (!customerAddress.lat || !customerAddress.lng)
        return undefined;
    const geoLocations = [{ lat: customerAddress.lat, lng: customerAddress.lng }];

    for (const secondaryAddress of secondaryAddresses) {
        if (secondaryAddress.lat && secondaryAddress.lng) {
            geoLocations.push({ lat: secondaryAddress.lat, lng: secondaryAddress.lng });
        }
    }
    return geoLocations;
};

export type { Coordinates };