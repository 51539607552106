import { Timestamp, FirestoreDataConverter, DocumentData, QueryDocumentSnapshot } from "firebase/firestore";
import { isObject } from "@marathon/common/objectHelper";

export class TimestampConverter<T extends DocumentData>
    implements FirestoreDataConverter<DocumentData> {
    toFirestore(modelObject: DocumentData): DocumentData {
        return modelObject;
    }

    fromFirestore(snapshot: QueryDocumentSnapshot): T {
        const data = snapshot.data();

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const resolveValue = (value: unknown, key: string | number, object: any) => {
            if (value instanceof Timestamp) {
                object[key] = value.toDate();
            } else if (Array.isArray(value) || isObject(value)) {
                go(value);
            }
        };

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const go = (object: any) => {
            if (Array.isArray(object)) {
                object.forEach((value, i, array) => resolveValue(value, i, array));
            } else if (isObject(object)) {
                Object.keys(object).forEach(key => resolveValue(object[key], key, object));
            }
        };

        go(data);

        return data as T;
    }
}