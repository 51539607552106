import { Hub } from "@marathon/common/entities/Hub";
import { DateTime } from "luxon";
import { getOrdinalSuffix } from "@marathon/common/timeFormatHelper";

// HACK We need to add this logic since the luxon library doesn't work properly in iOS
// See this issue for reference: https://github.com/moment/luxon/issues/1500
const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

export const getDateText = (startTime: Date, customerHub: Hub) => {
    const fromDateTime = DateTime.fromJSDate(startTime, { zone: customerHub.time_zone });
    const weekday = fromDateTime.weekdayLong || daysOfWeek[fromDateTime.weekday];

    return `${weekday}, ${fromDateTime.toFormat("MMM dd")}${getOrdinalSuffix(fromDateTime.day)}`;
};

export const getTimeText = (startTime: Date, endTime: Date, customerHub: Hub) => {
    const fromDateTime = DateTime.fromJSDate(startTime, { zone: customerHub.time_zone });
    const toDateTime = DateTime.fromJSDate(endTime, { zone: customerHub.time_zone });

    return `${fromDateTime.toFormat("h:mm a")}-${toDateTime.toFormat("h:mm a")}`;
};

export const getFrecuencyText = (interval: number) => {
    return `Every ${interval} ${interval === 1 ? "week" : "weeks"}`;
};