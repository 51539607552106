import { CustomerAddressData } from "./entities/Customer";
import { SecondaryAddressData } from "./entities/SecondaryAddress";

export function getAddressParts(address: { address1: string, address2?: string, state: string, city: string, zip?: string }, withAddress2 = false) {
    const addressParts = [];

    if (withAddress2 && address.address2) {
        addressParts.push(`${address.address1} ${address.address2}`);
    }
    else {
        addressParts.push(address.address1);
    }

    addressParts.push(address.city);
    addressParts.push(address.state);

    if (address.zip)
        addressParts.push(address.zip);

    return addressParts;
}

export function getGoogleMapsUrl(address: { address1: string, state: string, city: string, zip?: string }) {
    const addressParts = getAddressParts(address);
    return `https://www.google.com/maps/place/${addressParts.filter(x => x).join("+")}`;
}

export const getAddressLabel = (address: CustomerAddressData) => {
    const line = [address.address1, address.address2, address.state, address.zip];
    return line.filter((x) => x).join(", ");
};

export const getAddressCoordinates = (address: CustomerAddressData | SecondaryAddressData) => {
    if (address && address.lat && address.lng) {
        return {
            lat: address.lat,
            lng: address.lng
        };
    }
    else {
        return undefined;
    }
};