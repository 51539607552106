import { MobileServiceFeeConfiguration } from "@marathon/common/entities/Configuration";
import { DiscountData, DiscountType } from "@marathon/common/entities/Discount";
import { Customer } from "@marathon/common/entities/Customer";
import { Discount } from "@marathon/common/entities/Discount";
import { Hub } from "@marathon/common/entities/Hub";
import { DiscountCode } from "@marathon/common/entities/DiscountCode";

export const FIRST_TIME_CUSTOMER_EXTRA_DISCOUNT = 10;

export interface GetFirstOccurrenceDiscountInput {
    customer: Customer;
    hubs: Hub[];
    discounts: Discount[];
    interval?: number;
    invitationCode?: string;
}

export interface GetFirstTimeCustomerDiscountInput {
    customer: Customer;
    hubs: Hub[];
    interval?: number;
    invitationCode?: string;
}

export class DiscountUtils {
    private static getDiscountByType(discounts: Discount[], type: DiscountType): DiscountData | undefined {
        return discounts.find(x => x.type === type)?.toData();
    }

    public static getReferralDiscount(discounts: Discount[]): DiscountData | undefined {
        return this.getDiscountByType(discounts, DiscountType.referral);
    }

    public static getMobileServiceFeeDiscount(
        customer: Customer,
        interval: number,
        mobileServiceFee: MobileServiceFeeConfiguration,
        discounts: Discount[]
    ): DiscountData | undefined {
        return (
            customer.qualifiesFor.mobileServiceFeeDiscount(mobileServiceFee) &&
                Discount.qualifiesForRecurrenceDiscount(interval)
                ? this.getDiscountByType(discounts, DiscountType.mobileServiceFee)
                : undefined
        );
    }

    public static getFirstOccurrenceDiscount(input: GetFirstOccurrenceDiscountInput): DiscountData | undefined {
        const { customer, hubs, discounts, interval, invitationCode } = input;
        if (!customer.qualifiesFor.firstOccurrenceDiscount(hubs, invitationCode))
            return undefined;

        const firstOccurrenceDiscount = this.getDiscountByType(discounts, DiscountType.firstOccurrence);
        const isQualifyingRecurrence = Discount.qualifiesForRecurrenceDiscount(interval);

        return (
            isQualifyingRecurrence && firstOccurrenceDiscount
                ? firstOccurrenceDiscount
                : undefined
        );
    }

    public static getFirstTimeCustomerDiscount(input: GetFirstTimeCustomerDiscountInput): DiscountData | undefined {
        const { customer, hubs, interval, invitationCode } = input;
        if (!customer.qualifiesFor.firstTimeCustomerDiscount(hubs, invitationCode))
            return undefined;

        const customerHub = hubs.find(x => x.id === customer.address.drive_time?.hub_id);
        const discountConfiguration = customerHub?.first_time_customer_discount;
        if (!discountConfiguration?.is_enabled)
            return undefined;

        const discount = {
            type: DiscountType.firstTimeCustomer,
            value: discountConfiguration.value
        };

        if (interval && discount && Discount.qualifiesForRecurrenceDiscount(interval)) {
            discount.value = discount.value + FIRST_TIME_CUSTOMER_EXTRA_DISCOUNT;
        }

        return discount;
    }

    public static getAppointmentDiscounts(
        customer: Customer,
        isFromOnlineBooking: boolean,
        hubs: Hub[],
        discounts: Discount[],
        mobileServiceFee: MobileServiceFeeConfiguration,
        interval?: number,
        invitationCode?: string,
        discountCode?: DiscountCode
    ): DiscountData[] {
        if (discountCode) {
            return [discountCode.toDiscountData()];
        } else {
            const appointmentDiscounts: DiscountData[] = [];

            const mobileServiceFeeDiscount = interval ? this.getMobileServiceFeeDiscount(customer, interval, mobileServiceFee, discounts) : undefined;
            mobileServiceFeeDiscount && appointmentDiscounts.push(mobileServiceFeeDiscount);

            if (isFromOnlineBooking) {
                if (invitationCode) {
                    const referralDiscount = this.getReferralDiscount(discounts);
                    referralDiscount && appointmentDiscounts.push(referralDiscount);
                }

                const firstOccurrenceDiscount = interval ? this.getFirstOccurrenceDiscount({ customer, hubs, discounts, interval, invitationCode }) : undefined;
                firstOccurrenceDiscount && appointmentDiscounts.push(firstOccurrenceDiscount);

                const firstTimeCustomerDiscount = this.getFirstTimeCustomerDiscount({ customer, hubs, interval, invitationCode });
                firstTimeCustomerDiscount && appointmentDiscounts.push(firstTimeCustomerDiscount);
            }

            return appointmentDiscounts;
        }
    }
}