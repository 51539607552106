import { useCallback, useEffect, useState } from "react";
import { Button } from "@marathon/web/components/Button";
import useStyles from "@marathon/web/components/onlineBooking/styles";
import { Box, TextField } from "@mui/material";
import { DateTime } from "luxon";
import { OnlineBookingContentWrapper } from "../OnlineBookingContentWrapper";
import { Customer } from "@marathon/common/entities/Customer";
import { BookingSuggestion, getSuggestionExactTime, getSuggestionTimeWindow } from "@marathon/common/api/BookingSuggestion";
import { Pet } from "@marathon/common/entities/Pet";
import { Service } from "@marathon/common/entities/Service";
import { Breed } from "@marathon/common/entities/Breed";
import { MobileServiceFeeConfiguration } from "@marathon/common/entities/Configuration";
import { leadOutcomes } from "@marathon/common/constants";
import * as Sentry from "@sentry/react";
import { Credit } from "@marathon/common/entities/Credit";
import { Discount, DiscountLabel, DiscountType } from "@marathon/common/entities/Discount";
import { TitleWrapper, ContentWrapper } from "./CommonWrappers";
import { SecondaryAddress } from "@marathon/common/entities/SecondaryAddress";
import { CustomerAddressData } from "@marathon/common/entities/Customer";
import { getMobileServiceFeePrice } from "@marathon/common/mobileServiceFeeHelper";
import { Hub } from "@marathon/common/entities/Hub";
import { DiscountUtils } from "@marathon/common/DiscountUtils";
import { DiscountCode } from "@marathon/common/entities/DiscountCode";

interface ConfirmAppointmentProps {
    customer: Customer,
    suggestion: BookingSuggestion,
    onConfirmBooking: () => void,
    onGoToSafety?: () => void,
    pets: Pet[],
    services: Service[],
    selectedServices: { petId: string, serviceId: string }[],
    mobileServiceFee: MobileServiceFeeConfiguration,
    tryUpdateLeadInfoOutcome?: (initialStatus: string, finalStatus: string) => Promise<void>,
    appointmentNotes: string,
    setAppointmentNotes: (value: string) => void,
    bookAppointment: () => Promise<void>,
    frequency: { interval: number, type: string } | null,
    discounts: Discount[],
    credits?: Credit[],
    invitationCode?: string,
    selectedAddress: SecondaryAddress | CustomerAddressData,
    hubs: Hub[],
    discountCode?: DiscountCode
}

export default function ConfirmAppointment({
    customer,
    suggestion,
    onConfirmBooking,
    onGoToSafety,
    pets,
    services,
    selectedServices,
    mobileServiceFee,
    tryUpdateLeadInfoOutcome,
    appointmentNotes,
    setAppointmentNotes,
    bookAppointment,
    frequency,
    discounts,
    credits,
    invitationCode,
    selectedAddress,
    hubs,
    discountCode
}: ConfirmAppointmentProps) {

    const classes = useStyles();
    const [totalPrice, setTotalPrice] = useState<number | null>(null);
    const [isConfirmingBooking, setIsConfirmingBooking] = useState(false);
    const [showNoteTextInput, setShowNoteTextInput] = useState(false);
    const [showMattedDogFeeText, setShowMattedDogFeeText] = useState(false);
    const appointmentDiscounts = DiscountUtils.getAppointmentDiscounts(customer, true, hubs, discounts, mobileServiceFee, frequency?.interval, invitationCode, discountCode);

    //TODO: Refactor this business logic into an entity or helper function.
    const msfDiscount = appointmentDiscounts.find(x => x.type === DiscountType.mobileServiceFee);
    const msfNormalPrice = mobileServiceFee.is_enabled && !customer.skip_mobile_service_fee ? getMobileServiceFeePrice(mobileServiceFee, customer) : undefined;
    const msfPrice = msfNormalPrice && msfDiscount ? msfNormalPrice - msfDiscount.value : msfNormalPrice;

    const getTotalPrice = useCallback(() => {
        const estimatedPrice = Service.getServicesGrossPrice(selectedServices, pets);
        const priceWithCreditDiscount = credits ? Credit.apply(credits, estimatedPrice) : estimatedPrice;
        const subTotal = Discount.apply(appointmentDiscounts, priceWithCreditDiscount);
        return (
            subTotal + (msfNormalPrice || 0)
        );
    }, [selectedServices, pets, credits, appointmentDiscounts, msfNormalPrice]);

    useEffect(() => {
        const setTotal = async () => {
            const total = getTotalPrice();
            setTotalPrice(total);
        };
        setTotal();
    }, [selectedServices, mobileServiceFee, pets, customer, getTotalPrice]);

    useEffect(() => {
        const mattedDogServices = services.filter(x =>
            Breed.getMattedDogAcronyms().some(y => x.name.startsWith(y))
        );
        setShowMattedDogFeeText(
            selectedServices.some(x => mattedDogServices.map(y => y.id).includes(x.serviceId))
        );
    }, [selectedServices, services]);

    const handleConfirmBooking = async () => {
        try {
            setIsConfirmingBooking(true);

            if (!customer.safety_accepted && onGoToSafety) {
                await tryUpdateLeadInfoOutcome?.(leadOutcomes.open_confirmation, leadOutcomes.open_safety_page);
                onGoToSafety();
                return;
            }

            await bookAppointment();
            await tryUpdateLeadInfoOutcome?.(leadOutcomes.open_confirmation, leadOutcomes.closed_won);

            onConfirmBooking();
        }
        catch (error) {
            Sentry.captureException(error);
        }
        finally {
            setIsConfirmingBooking(false);
        }
    };

    return (
        <OnlineBookingContentWrapper>
            <TitleWrapper title="Confirmation" />
            <ContentWrapper>
                <Box display="flex" flexDirection="column" lineHeight="1rem" alignItems="center"  >
                    <div className="confirm-page-price">
                        <div className="total">Total</div>
                        <div className="price">{`$${totalPrice}`}</div>
                    </div>
                </Box>
                {selectedServices.length > 0 &&
                    <Box width={{ xs: "100%", sm: "600px" }}>
                        <Box display="flex" flexDirection="column" lineHeight="1rem" alignItems="center"  >
                            <div className="confirm-page-service">
                                {selectedServices.map((ps, idx) => {
                                    const pet = pets.find(p => p.id === ps.petId);
                                    if (!pet)
                                        return "";
                                    const service = services.find(s => s.id === ps.serviceId);
                                    const price = pet?.services.find(s => s.id === ps.serviceId)?.price;
                                    return (
                                        <Box key={idx} display="flex" justifyContent="space-between">
                                            <div className="pet-name">
                                                <div>{pet.name}</div>
                                                <div className="description">{service?.description}</div>
                                            </div>
                                            <div className="price">
                                                {`$${price}`}
                                            </div>
                                        </Box>
                                    );
                                })}
                            </div>
                        </Box>
                    </Box>}
                {msfPrice && msfNormalPrice &&
                    <Box display="flex" flexDirection="column" lineHeight="1rem" alignItems="center">
                        <div className="confirm-page-mobile-service-fee">
                            <div className="service">
                                <div>Mobile Service Fee</div>
                            </div>
                            {msfPrice !== msfNormalPrice && <div className="price"><span style={{ color: "#68676791", textDecoration: "line-through" }}>{`$${msfNormalPrice}`}</span> {`${msfPrice}`}</div>}
                            {msfPrice === msfNormalPrice && <div className="price">{`$${msfPrice}`}</div>}
                        </div>
                    </Box>}
                {appointmentDiscounts.filter(x => x.type !== DiscountType.mobileServiceFee).map((discount, idx) => (
                    <Box key={idx} display="flex" flexDirection="column" lineHeight="1rem" alignItems="center">
                        <div className="confirm-page-discount">
                            <div className="discount">
                                <div>{Discount.getDescription(discount.type)}</div>
                            </div>
                            <div className="price">{`- $${discount.value}`}</div>
                        </div>
                    </Box>))}
                {credits && credits.length > 0 &&
                    <Box display="flex" flexDirection="column" lineHeight="1rem" alignItems="center"  >
                        <div className="confirm-page-mobile-service-fee">
                            <div className="service">
                                <div>{DiscountLabel.referralProgramCredit}</div>
                            </div>
                            <div className="price">{`- $${Credit.sum(credits)}`}</div>
                        </div>
                    </Box>}
                <Box className="confirm-page-footer">
                    <div>
                        Stylist: {suggestion.groomer.obfuscatedName}
                        <br />
                        Date: {DateTime.fromJSDate(suggestion.arrivalTime).toFormat("EEEE, MMMM dd, yyyy")}
                        <br />
                        {frequency &&
                            <>
                                Every {frequency.interval} {frequency.interval > 1 ? "weeks" : "week"}
                                <br />
                            </>}
                        {!suggestion.showExactTime && `Arrival window: ${getSuggestionTimeWindow(suggestion)}`}
                        {suggestion.showExactTime && ` Arrival time: ${getSuggestionExactTime(suggestion)}`}
                    </div>
                    <div>
                        {selectedAddress.address1}
                        <br />
                        {selectedAddress.city},{selectedAddress.state}, {selectedAddress.zip}
                        <br />
                        {customer.phone}
                    </div>
                </Box>
                {!showNoteTextInput &&
                    <span
                        className={classes.auxiliaryButton}
                        style={{ color: "#A2A2A2", marginTop: 15, float: "none", marginRight: "auto", width: "fit-content", display: "block" }}
                        onClick={() => setShowNoteTextInput(true)}
                    >
                        Add a note for this appointment
                    </span>
                }
                {showNoteTextInput &&
                    <TextField
                        autoComplete="off"
                        variant="outlined"
                        className="parking-notes"
                        color="primary"
                        size="medium"
                        placeholder="Special notes for this appointment"
                        value={appointmentNotes}
                        onChange={e => setAppointmentNotes(e.target.value)}
                    />}
                {showMattedDogFeeText && (
                    <Box sx={{
                        width: "100%",
                        textAlign: "left",
                        marginTop: "18px",
                        lineHeight: "1.5rem",
                    }}>
                        *Matted Dog Fee: Any severely matted coats will incur an additional charge of $100 per dog.
                        Gently removing knots and tangles requires additional time.
                        For more details, please call our Concierge team at 800.742.9255.
                    </Box>
                )}
                <Button
                    showSpinner={isConfirmingBooking}
                    variant="contained"
                    className={classes.button}
                    onClick={() => handleConfirmBooking()}
                >
                    Confirm &amp; book
                </Button>
            </ContentWrapper>
        </OnlineBookingContentWrapper>
    );
}
