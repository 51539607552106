import { useHistory, useLocation, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import CallableFunctions from "@marathon/client-side/utilities/CallableFunctions";
import { Customer } from "@marathon/common/entities/Customer";
import { BookingSuggestion } from "@marathon/common/api/BookingSuggestion";
import { Pet } from "@marathon/common/entities/Pet";
import steps from "@marathon/common/onlineBookingSteps";
import * as Sentry from "@sentry/react";
import { Credit } from "@marathon/common/entities/Credit";
import { OnlineBookingHeader } from "./OnlineBookingHeader";
import BookingSuggestionInnerContainer from "./BookingSuggestionInnerContainer";
import { Loading } from "@marathon/web/components/Loading";
import mixpanel from "mixpanel-browser";
import { DiscountCode } from "@marathon/common/entities/DiscountCode";

interface BookingSuggestionRouteParams {
    stepNumber?: string;
}

const BookingSuggestionContainer = () => {
    const location = useLocation();
    const history = useHistory();
    const routeParams = useParams<BookingSuggestionRouteParams>();

    const [stepNumber, setStepNumber] = useState(steps.returning.confirmation);
    const [customer, setCustomer] = useState<Customer | null>(null);
    const [bookedSuggestion, setBookedSuggestion] = useState<BookingSuggestion | null>(null);
    const [pets, setPets] = useState<Pet[]>([]);
    const [selectedServices, setSelectedServices] = useState<{ petId: string, serviceId: string }[]>([]);
    const [customerCredits, setCustomerCredits] = useState<Credit[]>([]);
    const [isValid, setIsValid] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [discountCode, setDiscountCode] = useState<DiscountCode>();

    const getBookingSuggestion = useCallback(async () => {
        try {
            const searchParams = new URLSearchParams(location.search);
            const suggestion = searchParams.get("suggestion");
            if (!suggestion) {
                return;
            }


            setIsLoading(true);

            const discountCode = searchParams.get("discountCode") ?? undefined;
            const data = await CallableFunctions.current.public.getTokenizedSuggestionData(suggestion, discountCode);
            if (!data) {
                throw new Error(`Suggestion not valid: ${suggestion}`);
            }

            setCustomer(data.customer);
            mixpanel.identify(data.customer.email);
            setPets(data.pets);
            setCustomerCredits(data.credits.filter(x => !x.was_used));
            setBookedSuggestion(data.bookingSuggestion);
            setSelectedServices(data.selectedServices);
            setIsValid(data.isValid);
            setDiscountCode(data.discount);
        } catch (error) {
            console.error(error);
            Sentry.captureException(error);
        } finally {
            setIsLoading(false);
        }
    }, [location]);

    const goToStep = useCallback((step: number, shouldReplace?: boolean) => {
        const newUrl = `/booking-suggestion/${step}`;
        if (shouldReplace)
            history.replace(newUrl);
        else
            history.push(newUrl);
    }, [history]);

    useEffect(() => {
        setStepNumber(routeParams.stepNumber ? parseInt(routeParams.stepNumber) : steps.returning.confirmation);
    }, [routeParams.stepNumber]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [stepNumber]);

    useEffect(() => {
        getBookingSuggestion();
    }, [getBookingSuggestion]);

    if (isLoading) {
        return (
            <Loading />
        );
    }

    if (!customer || !bookedSuggestion) {
        return null;
    }

    return (
        <>
            <OnlineBookingHeader
                stepNumber={stepNumber}
                logoUrl={`/online-booking/${steps.returning.welcome}`}
            />
            <BookingSuggestionInnerContainer
                bookedSuggestion={bookedSuggestion}
                customer={customer}
                customerCredits={customerCredits}
                goToStep={goToStep}
                pets={pets}
                selectedServices={selectedServices}
                stepNumber={stepNumber}
                discountCode={discountCode}
                isValidSuggestion={isValid}
            />
        </>
    );
};

export default BookingSuggestionContainer;