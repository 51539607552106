import { PetService } from "@marathon/common/entities/Pet";

interface PetInput {
    id?: string,
    name?: string,
    breed_id?: string,
    weight?: number,
    age?: number,
    gender?: string,
    notes?: string,
    price?: number,
    service_id?: string,
    services?: ServiceInput[],
    profile_photo_url?: string
}

interface ServiceInput {
    id?: string,
    standard_price?: number,
    price?: number
}

export const defaultValues = { name: "", breed_id: "" } as PetInput;

export const serviceInputToPetService = (service: ServiceInput): PetService => {
    if (!service.id || !service.price)
        throw new Error("Invalid service input");
    return {
        id: service.id,
        price: service.price
    };
};

export type { PetInput, ServiceInput };