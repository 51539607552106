import { DailyAnnouncement, DailyAnnouncementData } from "@marathon/common/entities/DailyAnnouncement";
import LocalDate from "@marathon/common/LocalDate";
import { TimeZone } from "@marathon/common/timeZoneHelper";
import { DocResult, INJECTED_FIRESTORE_SERVICE_TOKEN } from "./IFirestoreService";
import type { IFirestoreService } from "./IFirestoreService";
import { container, inject, singleton } from "tsyringe";
import { CollectionPaths } from "@marathon/common/entities/base/CollectionPaths";
import { FilterOperator, getFilter, QueryFilter } from "@marathon/common/api/QueryFilter";

const mapEntity = function (snapshot: DocResult<DailyAnnouncementData>) {
    if (!snapshot.parentId) {
        throw new Error("Parent id is required");
    }
    return new DailyAnnouncement(snapshot.id, snapshot.parentId, snapshot.data);
};

@singleton()
export class DailyAnnouncementRepository {
    private firestoreService: IFirestoreService<DailyAnnouncementData>;
    constructor(@inject(INJECTED_FIRESTORE_SERVICE_TOKEN) injectedService: IFirestoreService<DailyAnnouncementData>) {
        injectedService.parentCollectionPath = CollectionPaths.Groomers;
        injectedService.collectionPath = CollectionPaths.DailyAnnouncements;
        this.firestoreService = injectedService;
    }
    static get current() {
        return container.resolve(DailyAnnouncementRepository);
    }
    async create(groomerId: string, data: DailyAnnouncementData) {
        return this.firestoreService.create(data, groomerId);
    }
    async search(sentDate: LocalDate, groomerId?: string) {
        const docs: DocResult<DailyAnnouncementData>[] = [];
        const filters: QueryFilter<DailyAnnouncementData>[] = [
            getFilter("sent_date", FilterOperator.greaterThanOrEqual, sentDate.toDayStart()),
            getFilter("sent_date", FilterOperator.lessThanOrEqual, sentDate.toDayEnd()),
            getFilter("time_zone", FilterOperator.equal, sentDate.contextTimeZone)
        ];
        if (groomerId) {
            docs.push(...await this.firestoreService.search({ filters }, groomerId));
        }
        else {
            docs.push(...await this.firestoreService.searchAcross({ filters }));
        }
        return docs.map(x => mapEntity(x));
    }
    async searchForAllHubs(fromDate: LocalDate) {
        //HACK: Since the from field is the start of the day, we always need to filter by timezone
        const dailyAnnouncements = await Promise.all(
            Object.values(TimeZone).map(async timeZone => this.search(LocalDate.fromLocalDate(fromDate, timeZone)))
        );
        return dailyAnnouncements.flat();
    }
}