import { Discount, DiscountData } from "@marathon/common/entities/Discount";
import { DocResult, INJECTED_FIRESTORE_SERVICE_TOKEN } from "./IFirestoreService";
import type { IFirestoreService } from "./IFirestoreService";
import { container, inject, singleton } from "tsyringe";
import { CollectionPaths } from "@marathon/common/entities/base/CollectionPaths";

const mapEntity = function (snapshot: DocResult<DiscountData>) {
    return new Discount(snapshot.id, snapshot.data);
};

@singleton()
export class DiscountRepository {
    private firestoreService: IFirestoreService<DiscountData>;
    constructor(@inject(INJECTED_FIRESTORE_SERVICE_TOKEN) injectedService: IFirestoreService<DiscountData>) {
        injectedService.collectionPath = CollectionPaths.Discounts;
        this.firestoreService = injectedService;
    }
    static get current() {
        return container.resolve(DiscountRepository);
    }
    async search() {
        const docs = await this.firestoreService.search();
        return docs.map(x => mapEntity(x));
    }
}
