import { DiscountCode, DiscountCodeData, DiscountCodeStatus } from "@marathon/common/entities/DiscountCode";
import { DocResult, INJECTED_FIRESTORE_SERVICE_TOKEN } from "./IFirestoreService";
import type { IFirestoreService } from "./IFirestoreService";
import { container, inject, singleton } from "tsyringe";
import { FilterOperator, getFilter } from "@marathon/common/api/QueryFilter";
import { CollectionPaths } from "@marathon/common/entities/base/CollectionPaths";
import { UpdateDataInternal } from "@marathon/common/typeUtils";

const mapEntity = function (snapshot: DocResult<DiscountCodeData>) {
    return new DiscountCode(snapshot.id, snapshot.data);
};

@singleton()
export class DiscountCodeRepository {
    private firestoreService: IFirestoreService<DiscountCodeData>;
    constructor(@inject(INJECTED_FIRESTORE_SERVICE_TOKEN) injectedService: IFirestoreService<DiscountCodeData>) {
        injectedService.collectionPath = CollectionPaths.DiscountCodes;
        this.firestoreService = injectedService;
    }
    static get current() {
        return container.resolve(DiscountCodeRepository);
    }
    async update(id: string, data: UpdateDataInternal<DiscountCodeData>) {
        await this.firestoreService.update(id, data);
    }
    async markAsUsed(id: string) {
        await DiscountCodeRepository.current.update(id, { status: DiscountCodeStatus.used });
    }
    async getByCode(code: string) {
        const docs = await this.firestoreService.search({
            filters: [
                getFilter("code", FilterOperator.equal, code)
            ]
        });
        const discountCode = docs.map(x => mapEntity(x)).at(0);
        return discountCode?.isValid() ? discountCode : null;
    }
}
