import { PetInput } from "@marathon/client-side/entities/PetInput";
import { formatNames } from "@marathon/common/textHelper";

const getPetNames = (pets: PetInput[]) => {
    return pets.map(x => x.name).filter((x): x is string => x !== undefined);
};

export const getAcknowledgementsDescriptions = (
    pets: PetInput[],
    petServices: {
        pet: string,
        serviceAcronym: string
    }[]
) => {
    if (pets.length === 0) return ["", ""];
    if (petServices.length === 0) return ["", ""];

    const getPetVerbToBe = (): string => {
        return pets.length > 1 ? "are" : "is";
    };

    const getPetVerbHave = (): string => {
        return pets.length > 1 ? "have" : "has";
    };

    const getCustomDescriptions = (hasMultipleOption: boolean) => {
        const secondParagraph = hasMultipleOption
            ? `${formatNames(getPetNames(pets), "&")} ${getPetVerbHave()} multiple options to choose from which all include a warm bath, a gentle brush out, & ear, teeth & nail care in our Mercedes grooming salon right at your doorstep.`
            : "All services include a warm bath, a gentle brush out, & ear, teeth & nail care in our Mercedes grooming salon right at your doorstep.";
        return [
            `Every Barkbus experience is 1 on 1 so ${formatNames(getPetNames(pets), "&")} ${getPetVerbToBe()} safe & stress-free with our gentle and compassionate expert Pet Stylist.`,
            secondParagraph,
        ];
    };

    const getConfigurations = () => [
        {
            acronyms: ["CR", "LH", "LD"],
            descriptions: getCustomDescriptions(true),
            weight: 1
        },
        {
            acronyms: ["SD", "SH"],
            descriptions: getCustomDescriptions(false),
            weight: 2
        }
    ];

    const petConfigurations = petServices.map(petService => {
        const configurations = getConfigurations();
        const petServiceConfiguration = configurations.find(d => d.acronyms.includes(petService.serviceAcronym));
        return (
            petServiceConfiguration ?? configurations[0]
        );
    });

    return petConfigurations.sortByFieldAscending(x => x.weight)[0].descriptions;
};

export const getAcknowledgementsTitle = (pets: PetInput[]) => {
    return `${formatNames(getPetNames(pets), "&")} can say goodbye to grooming cages forever!`;
};