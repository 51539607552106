import { useState } from "react";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Box,
} from "@mui/material";
import useStyles from "@marathon/web/components/onlineBooking/styles";
import { ReactComponent as CheckSvg } from "images/check-safety.svg";
import { Button } from "@marathon/web/components/Button";
import { OnlineBookingContentWrapper } from "../OnlineBookingContentWrapper";
import * as Sentry from "@sentry/react";
import { CustomerRepository } from "@marathon/client-side/repositories/CustomerRepository";
import { leadOutcomes } from "@marathon/common/constants";
import { UpdateData } from "firebase/firestore";
import { Customer, CustomerData } from "@marathon/common/entities/Customer";
import { TitleWrapper, ContentWrapper, FieldsetWrapper } from "./CommonWrappers";

interface ConfirmSafetyProps {
  customer: Customer,
  bookAppointment: () => Promise<void>,
  onNext: () => void,
}

export default function ConfirmSafety({ onNext, customer, bookAppointment }: ConfirmSafetyProps) {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleAcceptSafety = async () => {
    try {
      setIsLoading(true);
      const toUpdate = {
        safety_accepted: true
      } as UpdateData<CustomerData>;
      if (!customer.lead_info || customer.lead_info.outcome === leadOutcomes.open_safety_page) {
        toUpdate["lead_info.outcome"] = leadOutcomes.closed_won;
      }
      await CustomerRepository.current.updateFromOnlineBooking(customer.id, toUpdate);
      await bookAppointment();
      onNext();
    }
    catch (error) {
      Sentry.captureException(error);
    }
    finally {
      setIsLoading(false);
    }
  };

  return (
    <OnlineBookingContentWrapper>
      <TitleWrapper title="Safety Form" />
      <ContentWrapper className="content-safety">
        <div>
          Safety is of the utmost importance at Barkbus.
          <br />
          Please confirm the following for your dog(s):
        </div>
        <div>
          <div style={{ float: "left" }}><CheckSvg /></div>
          <div style={{ marginLeft: 40 }}>They are current on all customary required vaccinations such as D.H.L.P.P, Bordetella and Rabies.</div>
        </div>
        <div>
          <div style={{ float: "left" }}> <CheckSvg /></div>
          <div style={{ marginLeft: 40 }}>They have not shown any threatening behaviour to other dogs or people.</div>
        </div>
        <div>
          <div style={{ float: "left" }}> <CheckSvg /></div>
          <div style={{ marginLeft: 40 }}>They have no behavioral issues as a result of being confined in a small space.</div>
        </div>
        <div>
          <div style={{ float: "left" }}><CheckSvg /></div>
          <div style={{ marginLeft: 40 }}>They have not bitten another human, causing broken bones and/or a laceration that required multiple stitches to treat.</div>
        </div>
        <div>
          <div style={{ float: "left" }}><CheckSvg /></div>
          <div style={{ marginLeft: 40 }}>They do not suffer a disability, illness or other condition that could have an adverse effect on the health, or could jeopardize the safety of another dog or human.</div>
        </div>
        <div>
          <div style={{ float: "left" }}><CheckSvg /></div>
          <div style={{ marginLeft: 40 }}>They have not had any general surgery within the past 14 days and do not have any open wounds or stitches at the time of the Service.</div>
        </div>
        <FieldsetWrapper>
          <FormGroup>
            <FormControlLabel
              style={{
                width: "100%",
                border: "1px solid #D7D7D7",
                borderRadius: 5,
                padding: 10,
                marginTop: 24,
                marginLeft: "auto",
                marginRight: "auto"
              }}
              control={
                <Checkbox
                  style={{
                    color: checked ? "#0066FF" : ""
                  }}
                  color="primary"
                  checked={checked ?? false}
                  onChange={e => setChecked(e.target.checked)
                  }
                />
              }
              label={
                <Box
                  fontSize="16px"
                  color="#000000"
                  fontWeight="600"
                  textAlign="center"
                  lineHeight="16px"
                >
                  I confirm the above
                </Box>
              }
            />
          </FormGroup>
          <Button
            showSpinner={isLoading}
            disabled={!checked}
            onClick={() => handleAcceptSafety()}
            variant="contained"
            className={classes.button}
            disableElevation
          >
            Submit
          </Button>
        </FieldsetWrapper>
      </ContentWrapper>
    </OnlineBookingContentWrapper>
  );
}