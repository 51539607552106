import { DateTime } from "luxon";
import { DayOfWeek, DEFAULT_MAXIMUM_DISTANCE, DEFAULT_MAXIMUM_DRIVE_TIME, monthsRangeList, zeroBasedDaysOfWeek } from "./constants";
import { TimeZone } from "./timeZoneHelper";

const YEAR_IN_MILLIS = 31536000000;
const MONTH_IN_MILLIS = YEAR_IN_MILLIS / 12;
export const MAX_DAYS_FOR_GOOGLE_TASK = 30;
export const ROUND_TO_WINDOW_MINUTES = 15;

const getMonths = (date: Date) => (Date.now() - +date) / MONTH_IN_MILLIS;

export function getDateFromISOString(isoString: string, timezone: TimeZone) {
    return DateTime.fromISO(isoString, { zone: timezone }).toJSDate();
}

export function getDatePlusHours(date: Date, hours: number) {
    return DateTime.fromJSDate(date).plus({ hours }).toJSDate();
}

export function getDatePlusMinutes(date: Date, minutes: number) {
    return DateTime.fromJSDate(date).plus({ minutes }).toJSDate();
}

export function getBirthday(age: number) {
    return DateTime.now().minus({ years: age }).toJSDate();
}

export function getDateStringFromTimeOffset(time: number, timeOffsetInMinutes: number) {
    return new Date(time - timeOffsetInMinutes * 60 * 1000).toISOString();
}

export function getDateFromISOTime(time: number, timeOffsetInMinutes: number) {
    return new Date(time + timeOffsetInMinutes * 60 * 1000);
}

export function getYYYYMMDDFromISOTime(time: string) {
    const timeNumber = parseInt(time);
    if (!timeNumber || isNaN(timeNumber)) return undefined;
    return new Date(parseInt(time)).toISOString().split("T")[0];
}

export function getMinutesBetweenDates(startDate: Date, endDate: Date) {
    return (!startDate || !endDate
        ? 0
        : DateTime.fromJSDate(endDate).diff(DateTime.fromJSDate(startDate), "minutes").minutes);
}

export function getDaysBetweenDates(startDate: Date, endDate: Date) {
    return (
        !startDate || !endDate
            ? 0
            : DateTime.fromJSDate(endDate).diff(DateTime.fromJSDate(startDate), "days").days
    );
}

export function getHoursBetweenDates(startDate: Date, endDate: Date) {
    return (
        !startDate || !endDate
            ? 0
            : DateTime.fromJSDate(endDate).diff(DateTime.fromJSDate(startDate), "hours").hours
    );
}

export function roundToNearestQuarter(date: Date) {
    const coefficient = 1000 * 60 * ROUND_TO_WINDOW_MINUTES;
    return new Date(Math.round(date.getTime() / coefficient) * coefficient);
}

export function calculatePetAge(birthday: Date): number {
    const age = (Date.now() - +birthday) / YEAR_IN_MILLIS;
    if (~~age < 1) {
        const months = getMonths(birthday);
        return monthsRangeList.filter((mo) => mo.from <= months && mo.to > months)[0].avr_in_years;
    }
    else {
        return ~~age;
    }
}

export function getPetAgeText(age: number): string {
    if (~~age < 1) {
        return monthsRangeList.filter(mr => mr.avr_in_years === age)[0].text;
    }
    else if (~~age >= 20) {
        return "20+ Yr";
    }
    else {
        return `${~~age} Yr`;
    }
}

export const isWithinMaximumDistance = (distanceMiles: number, maximum: number | undefined) => {
    return distanceMiles <= (maximum ?? DEFAULT_MAXIMUM_DISTANCE);
};

export const isWithinMaximumDriveTime = (driveTimeSeconds: number, maximum: number | undefined) => {
    return driveTimeSeconds <= (maximum ?? DEFAULT_MAXIMUM_DRIVE_TIME);
};

export const daysAgoToDate = (days: number) => {
    const date = new Date();
    return new Date(date.setDate(date.getDate() - days));
};

export const daysUntilToDate = (days: number) => {
    const date = new Date();
    return new Date(date.setDate(date.getDate() + days));
};

export const getUnixSeconds = (date: Date) => {
    return Math.round((date.getTime() / 1000));
};

export const getDurationTextFromMinutes = (minutes: number) => {
    if (isNaN(minutes) || minutes < 0) {
        return 0;
    }
    else if (minutes < 60) {
        return `${minutes} min`;
    }
    else if (Number(minutes) === 60) {
        return "1 hr";
    }
    else {
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;
        return `${hours} ${hours > 1 ? "hrs" : "hr"}${remainingMinutes > 0 ? ` ${remainingMinutes} min` : ""}`;
    }
};

export const getTimeSince = (date: Date) => {
    const fromJS = DateTime.fromJSDate(date);
    const hoursAgo = DateTime.now().diff(fromJS, "hours").hours;
    if (hoursAgo < 1) {
        return (
            DateTime.now().diff(fromJS, "minutes").minutes < 1
                ? "just now"
                : fromJS.toRelativeCalendar({ unit: "minutes" })
        );
    }
    else if (hoursAgo > 24)
        return fromJS.toRelativeCalendar({ unit: "days" });
    else
        return fromJS.toRelativeCalendar({ unit: "hours" });
};

export const getFullDateSince = (date: Date) => {
    const addZero = (value: number) => {
        return String(value).padStart(2, "0");
    };
    const timeSince = getTimeSince(date);
    return `${addZero(date.getMonth() + 1)}/${addZero(date.getDate())}/${date.getFullYear()}, ${timeSince}`;
};

export const getTimeUntil = (date: Date) => {
    const fromJS = DateTime.fromJSDate(date);
    const hoursAgo = fromJS.diff(DateTime.now(), "hours").hours;
    if (hoursAgo < 1) {
        return (
            DateTime.now().diff(fromJS, "minutes").minutes < 1
                ? "just now"
                : fromJS.toRelativeCalendar({ unit: "minutes" })
        );
    }
    else if (hoursAgo > 24)
        return fromJS.toRelativeCalendar({ unit: "days" });
    else
        return fromJS.toRelativeCalendar({ unit: "hours" });
};

export const dateToMMDDYY = (date: Date) => {
    const d = date.getDate();
    const m = date.getMonth() + 1;
    const y = date.getFullYear();

    return `${m}/${d}/${y}`;
};

export const dateRangeOverlaps = (targetRange: DateRange, otherRanges: DateRange[]): boolean => {
    return otherRanges.some(x => areOverlapping(targetRange, x));
};

export const areOverlapping = (range: DateRange, other: DateRange) => {
    return (
        range.startTime < other.endTime &&
        range.endTime > other.startTime
    );
};

export const getOverlappingDuration = (range: DateRange, other: DateRange) => {
    return (
        Math.min(
            range.endTime.getTime() - range.startTime.getTime(),
            range.endTime.getTime() - other.startTime.getTime(),
            other.endTime.getTime() - range.startTime.getTime(),
            other.endTime.getTime() - other.startTime.getTime()
        )
    );
};

export const getDayOfWeekSinceTomorrow = () => {

    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    const startDate = new Date(tomorrow);
    const endDate = new Date(tomorrow);
    endDate.setDate(endDate.getDate() + 6);

    const weekDays = [];

    while (startDate <= endDate) {
        weekDays.push(startDate.getDay());
        startDate.setDate(startDate.getDate() + 1);
    }

    return weekDays;
};

export const formatTimeWithSuffix = (date: DateTime) => {
    const suffix = date.hour >= 12 ? "pm" : "am";
    return suffix;
};

export const isValidGoogleTaskDate = (date: Date | null) => {
    if (!date) {
        return false;
    }

    const currentTime = new Date();
    const timeDifference = date.getTime() - currentTime.getTime();
    const daysDifference = timeDifference / (1000 * 3600 * 24);
    return daysDifference <= MAX_DAYS_FOR_GOOGLE_TASK;
};

export interface DateRange {
    startTime: Date,
    endTime: Date
}

export const getDayNumber = (dayName: DayOfWeek) => {
    return zeroBasedDaysOfWeek.indexOf(dayName);
};

export const getFirstDayOfMonthMatching = (dayOfWeek: DayOfWeek, timeZone: TimeZone) => {
    const now = DateTime.now().setZone(timeZone).plus({ month: 1 }).startOf("month");
    const currentDayIndex = now.weekday;
    const targetDayIndex = getDayNumber(dayOfWeek);
    const offset = Math.abs(targetDayIndex - currentDayIndex);

    if (targetDayIndex > currentDayIndex) {
        return now.plus({ days: offset });
    } else {
        return now.plus({ days: 7 - offset });
    }
};

export const getUnixTimestampsForYesterday = () => {
    const startOfYesterday = new Date();
    startOfYesterday.setDate(startOfYesterday.getDate() - 1);
    startOfYesterday.setHours(0, 0, 0, 0);
    const startTimestamp = Math.floor(startOfYesterday.getTime() / 1000);

    const endOfYesterday = new Date(startOfYesterday);
    endOfYesterday.setHours(23, 59, 59, 999);
    const endTimestamp = Math.floor(endOfYesterday.getTime() / 1000);

    return { startTimestamp, endTimestamp };
};