import * as Sentry from "@sentry/react";
import { MobileServiceFeeConfiguration } from "@marathon/common/entities/Configuration";
import { BookingSuggestion } from "@marathon/common/api/BookingSuggestion";
import { ReferralEventType } from "@marathon/common/entities/ReferralActivity";
import { AppointmentRepository } from "@marathon/client-side/repositories/AppointmentRepository";
import { AppointmentInput } from "@marathon/client-side/entities/AppointmentInput";
import { AppointmentPetInput } from "@marathon/client-side/entities/AppointmentPetInput";
import { Hub } from "@marathon/common/entities/Hub";
import { RecurrenceRepository } from "@marathon/client-side/repositories/RecurrenceRepository";
import { ReferralActivityRepository } from "@marathon/client-side/repositories/ReferralActivityRepository";
import { DiscountUtils } from "@marathon/common/DiscountUtils";
import { DiscountCodeRepository } from "@marathon/client-side/repositories/DiscountCodeRepository";
import { SelectedService } from "@marathon/common/api/SelectedService";
import { Groomer } from "@marathon/common/entities/Groomer";
import { Service } from "@marathon/common/entities/Service";
import { Discount } from "@marathon/common/entities/Discount";
import { SecondaryAddress } from "@marathon/common/entities/SecondaryAddress";
import { PricingPreset } from "@marathon/common/entities/PricingPreset";
import { Credit } from "@marathon/common/entities/Credit";
import { Customer } from "@marathon/common/entities/Customer";
import { Pet } from "@marathon/common/entities/Pet";
import { DiscountCode } from "@marathon/common/entities/DiscountCode";
import { CreditRepository } from "@marathon/client-side/repositories/CreditRepository";
import { Appointment } from "@marathon/common/entities/Appointment";

interface BookAppointmentParams {
    suggestion: BookingSuggestion;
    groomer: Groomer;
    customer: Customer;
    pets: Pet[];
    services: Service[];
    isFromSignup: boolean;
    assignments: { petId: string, serviceId: string }[];
    mobileServiceFee: MobileServiceFeeConfiguration;
    isFromSms: boolean;
    isFromOnlineBooking: boolean;
    frequency: { interval: number, type: string } | null;
    notesFromCustomer?: string;
    discounts: Discount[];
    hubs: Hub[];
    customerCredits?: Credit[];
    invitationCode?: string;
    secondaryAddress?: SecondaryAddress;
    notes?: string;
    discountCode?: DiscountCode;
    presetForAddOnServices?: PricingPreset;
}

export async function bookAppointment({
    suggestion,
    groomer,
    customer,
    pets,
    services,
    isFromSignup,
    assignments,
    mobileServiceFee,
    isFromSms,
    isFromOnlineBooking,
    frequency,
    notesFromCustomer,
    discounts,
    hubs,
    customerCredits,
    invitationCode,
    secondaryAddress,
    notes,
    discountCode,
    presetForAddOnServices
}: BookAppointmentParams) {

    const input = new AppointmentInput(null, mobileServiceFee, customer);

    if (customer.skip_mobile_service_fee)
        input.extraItems = [];

    input.startTime = suggestion.arrivalTime;
    input.selectedPets = presetForAddOnServices
        ? getCombinedSelectedPets(assignments, pets, presetForAddOnServices)
        : Appointment.getSelectedPets(assignments, pets).map(x => new AppointmentPetInput(x));
    input.isFromSignup = isFromSignup;
    input.isFromOnlineBooking = isFromOnlineBooking;
    input.isFromSms = isFromSms;
    input.notes = notes ?? "";
    input.frequency = frequency;
    input.notesFromCustomer = notesFromCustomer;
    input.secondaryAddressId = secondaryAddress ? secondaryAddress.id : undefined;
    input.discounts = DiscountUtils.getAppointmentDiscounts(customer, isFromOnlineBooking, hubs, discounts, mobileServiceFee, frequency?.interval, invitationCode, discountCode);
    const newAppointment = AppointmentRepository.getAppointmentToCreate(input, groomer, customer, services, pets, secondaryAddress);

    if (customerCredits) {
        newAppointment.credits = customerCredits.map((credit) => ({
            id: credit.id,
            value: credit.value,
            customerId: credit.customerId,
            customer_referred: credit.customer_referred,
            customer_referred_id: credit.customer_referred_id,
            was_used: credit.was_used
        }));

        await updateCredits(customer.id, customerCredits);
    }

    try {
        if (isFromOnlineBooking && invitationCode) {
            newAppointment.invitation_code = invitationCode;

            await ReferralActivityRepository.current.create(
                customer,
                ReferralEventType.BookDog,
                `${process.env.REACT_APP_CUSTOMERS_BASE_URL}/online-booking-signup?invitation=${invitationCode}`
            );
        }

        if (discountCode)
            await DiscountCodeRepository.current.markAsUsed(discountCode.id);

        if (frequency) {
            return await RecurrenceRepository.current.create(newAppointment);
        }
        else {
            return await AppointmentRepository.current.create(newAppointment);
        }
    }
    catch (error) {
        Sentry.captureException(error);
        throw error;
    }
}

const updateCredits = async (customerId: string, credits: Credit[]) => {
    await Promise.all(
        credits.map((credit) =>
            CreditRepository.current.update(credit.id, { ...credit, was_used: true }, customerId)
        )
    );
};

const getCombinedSelectedPets = (assignments: SelectedService[], pets: Pet[], presetForAddOnServices: PricingPreset) => {
    return assignments
        .map(assignment => {
            const pet = pets.find(x => x.id === assignment.petId);
            if (!pet)
                throw new Error(`Pet not valid ${assignment.petId}`);

            const petService = pet.getService(assignment.serviceId, presetForAddOnServices);
            if (!petService)
                throw new Error(`Service not valid ${assignment.serviceId}`);

            return Appointment.getSelectedPet(pet, petService);
        })
        .map(x => new AppointmentPetInput(x));
};