import { useState } from "react";
import { Box } from "@mui/material";
import Swal from "sweetalert2";
import { errorMessages } from "@marathon/common/constants";
import squareLogoImg from "images/square.jpg";
import useImagePreloader from "@marathon/web/hooks/useImagePreloader";
import { Loading } from "@marathon/web/components/Loading";
import { Button } from "@marathon/web/components/Button";
import useStyles from "@marathon/web/components/onlineBooking/styles";
import { Hub } from "@marathon/common/entities/Hub";
import { OnlineBookingContentWrapper } from "../OnlineBookingContentWrapper";
import { TitleWrapper, ContentWrapper } from "../shared/CommonWrappers";
import CreditCard from "@marathon/web/components/squareComponents/CreditCard";
import PaymentForm from "@marathon/web/components/squareComponents/PaymentForm";
import { Customer } from "@marathon/common/entities/Customer";

const preloadSrcList: string[] = [squareLogoImg];

interface Props {
  customer: Customer,
  onCardTokenized: (token: string) => Promise<void>,
  onSkipButtonClick?: () => Promise<void> | void
  isBooking?: boolean,
  hubs: Hub[]
}

const CreatePaymentMethod = ({ customer, onCardTokenized, isBooking, onSkipButtonClick, hubs }: Props) => {
  const { imagesPreloaded } = useImagePreloader(preloadSrcList);
  const classes = useStyles();

  const [isSkippingStep, setIsSkippingStep] = useState(false);

  const handleSkipButtonClick = async () => {
    if (onSkipButtonClick) {
      setIsSkippingStep(true);
      await onSkipButtonClick();
      setIsSkippingStep(false);
    }
  };

  if (!process.env.REACT_APP_SQUARE_APPLICATION_ID) {
    throw new Error("Missing Square Application ID");
  }

  const locationId = customer.getSquareLocationId(hubs, process.env.REACT_APP_SQUARE_SANDBOX_LOCATION_ID);
  if (!locationId) {
    return (
      <OnlineBookingContentWrapper>
        <TitleWrapper title="Add Payment Method" />
        <ContentWrapper>
          <Box textAlign="left">
            <p>{errorMessages.try_again_or_call_us}</p>
          </Box>
        </ContentWrapper>
      </OnlineBookingContentWrapper>
    );
  }
  else if (!imagesPreloaded) {
    return (
      <Loading />
    );
  }
  else {
    return (
      <OnlineBookingContentWrapper>
        <TitleWrapper title="Add Payment Method" />
        <ContentWrapper>
          <Box textAlign="left">
            <p>You will not be charged until your appointment has been completed</p>
          </Box>
          <PaymentForm
            applicationId={process.env.REACT_APP_SQUARE_APPLICATION_ID}
            cardTokenizeResponseReceived={async ({ status, token }) => {
              if (token && status === "OK") {
                await onCardTokenized(token);
              }
              else {
                Swal.fire({ title: "Error", text: "Invalid credit card details", icon: "error" });
              }
            }}
            locationId={locationId}
          >
            <CreditCard>
              {isBooking ? "Save & Book" : "Save"}
            </CreditCard>
          </PaymentForm>
          {isBooking &&
            <Button
              variant="outlined"
              color="secondary"
              showSpinner={isSkippingStep}
              className={classes.buttonSecondary}
              onClick={handleSkipButtonClick}
              disableElevation>
              Skip this step
            </Button>}
          <Box mt="52px">
            <p>Cards stored securely with Square</p>
            <Box width={{ xs: 116, sm: 232 }} marginX="auto">
              <img src={squareLogoImg} alt="Square" width="100%" height="100%" />
            </Box>
          </Box>
        </ContentWrapper>
      </OnlineBookingContentWrapper>
    );
  }
};

export default CreatePaymentMethod;