import { Customer } from "./entities/Customer";
import { MobileServiceFeeConfiguration } from "./entities/Configuration";
import LocalDate from "./LocalDate";
import { TimeZone } from "./timeZoneHelper";

export function getMobileServiceFeePrice(mobileServiceFee: MobileServiceFeeConfiguration, customer: Customer | null) {
    const localDate = LocalDate.forContextTimeZone(new Date(), TimeZone.PacificTime);
    if (customer &&
        mobileServiceFee.old_price &&
        localDate.isLessThanDate(mobileServiceFee.old_price.valid_to) &&
        customer.created_at < mobileServiceFee.old_price.created_at_to &&
        !customer.has_extra_large_pets) {
        return mobileServiceFee.old_price.value;
    }
    else {
        return mobileServiceFee.price;
    }
}