import { Customer } from "@marathon/common/entities/Customer";
import ConfirmAppointment from "./shared/ConfirmAppointment";
import { Pet } from "@marathon/common/entities/Pet";
import { Credit } from "@marathon/common/entities/Credit";
import * as Sentry from "@sentry/react";
import { BookingSuggestion } from "@marathon/common/api/BookingSuggestion";
import steps from "@marathon/common/onlineBookingSteps";
import { useOnlineBookingContext } from "./OnlineBookingContext";
import { bookAppointment } from "@marathon/client-side/helpers/bookingHelper";
import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import { Recurrence } from "@marathon/common/entities/Recurrence";
import { Appointment } from "@marathon/common/entities/Appointment";
import ThankYou from "./shared/ThankYou";
import { OnlineBookingFlowType, mixPanelEvent } from "@marathon/common/constants";
import { TrackData, trackEvent } from "./shared/tracker/useTracker";
import { formatShortDate, formatTime } from "@marathon/common/timeFormatHelper";
import { DiscountCode } from "@marathon/common/entities/DiscountCode";
import LeadExisting from "./newLead/LeadExisting";

interface BookingSuggestionInnerContainerProps {
    bookedSuggestion: BookingSuggestion,
    customer: Customer,
    customerCredits: Credit[],
    goToStep: (step: number, shouldReplace?: boolean | undefined) => void,
    pets: Pet[],
    selectedServices: { petId: string, serviceId: string }[],
    stepNumber: number,
    discountCode?: DiscountCode,
    isValidSuggestion: boolean
}

const BookingSuggestionInnerContainer = ({
    bookedSuggestion,
    customer,
    customerCredits,
    goToStep,
    pets,
    selectedServices,
    stepNumber,
    discountCode,
    isValidSuggestion
}: BookingSuggestionInnerContainerProps) => {
    const { groomers, services, mobileServiceFee, discounts, hubs } = useOnlineBookingContext();

    if (!mobileServiceFee)
        throw new Error("Mobile service fee configuration is required at this point");

    const [appointmentNotes, setAppointmentNotes] = useState("");
    const [appointment, setAppointment] = useState<Recurrence | Appointment | null>(null);
    const [isBlockedUser, setIsBlockedUser] = useState(false);

    const bookAppointmentInternal = async () => {
        try {
            if (!bookedSuggestion)
                throw new Error("Suggestion is required at this point");

            const groomer = groomers.find(x => x.id === bookedSuggestion.groomer.id);
            if (!groomer)
                throw new Error(`Groomer ${bookedSuggestion.groomer.id} not found`);

            if (!customer)
                throw new Error("Customer is required at this point");

            if (customer.blacklist) {
                setIsBlockedUser(true);
                throw new Error("Customer not valid for online booking");
            }

            const bookedAppointment = await bookAppointment({
                suggestion: bookedSuggestion,
                groomer,
                customer,
                pets,
                services,
                isFromSignup: false,
                assignments: selectedServices,
                mobileServiceFee,
                isFromSms: false,
                isFromOnlineBooking: true,
                frequency: null,
                notesFromCustomer: appointmentNotes,
                discounts,
                hubs,
                customerCredits,
                discountCode
            });
            const trackData: TrackData = {
                eventName: mixPanelEvent.ob_return_klaviyoBookingSuggestion_confirm,
                properties: {
                    new_user: false,
                    appointment_time: formatTime(bookedSuggestion.arrivalTime, bookedSuggestion.timeZone),
                    appointment_date: formatShortDate(bookedSuggestion.arrivalTime, bookedSuggestion.timeZone)
                }
            };
            trackEvent(trackData);
            setAppointment(bookedAppointment);
        }
        catch (error) {
            console.error(error);
            Sentry.captureException(error);
            Swal.fire({ title: "Sorry, an unexpected error has occurred. Please try again." });
            throw error;
        }
    };

    useEffect(() => {
        if (isBlockedUser) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong. Please try again later."
            });
        }
    }, [isBlockedUser]);

    if (!isValidSuggestion) {
        return (
            <LeadExisting
                title="Sorry, looks like that suggestion is no longer available."
                subtitle="Click the button below to look for other options"
                existingPhone={customer.phone}
                discountCode={discountCode?.code}
            />
        );
    }

    return (
        <>
            {stepNumber === steps.returning.confirmation &&
                <ConfirmAppointment
                    customer={customer}
                    suggestion={bookedSuggestion}
                    onConfirmBooking={() => goToStep(steps.returning.thankYou)}
                    pets={pets}
                    services={services}
                    selectedServices={selectedServices}
                    mobileServiceFee={mobileServiceFee}
                    appointmentNotes={appointmentNotes}
                    setAppointmentNotes={setAppointmentNotes}
                    bookAppointment={bookAppointmentInternal}
                    frequency={null}
                    discounts={discounts}
                    credits={customerCredits}
                    selectedAddress={customer.address}
                    hubs={hubs}
                    discountCode={discountCode}
                />}
            {stepNumber === steps.returning.thankYou &&
                <ThankYou
                    appointment={appointment}
                    suggestion={bookedSuggestion}
                    pets={pets}
                    flowType={OnlineBookingFlowType.returningCustomer}
                    hubs={hubs}
                    isFromBookingSuggestion={true}
                />}
        </>
    );
};

export default BookingSuggestionInnerContainer;