import { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import { Button } from "@marathon/web/components/Button";
import CircularProgress from "@mui/material/CircularProgress";
import FormHelperText from "@mui/material/FormHelperText";
import useStyles from "@marathon/web/components/onlineBooking/styles";
import { OnlineBookingContentWrapper } from "./OnlineBookingContentWrapper";
import { ConfirmationResult, PhoneAuthProvider, linkWithCredential } from "firebase/auth";
import { normalizePhone } from "@marathon/common/normalizationHelper";
import { useOnlineBookingContext } from "./OnlineBookingContext";
import ReactInputVerificationCode from "react-input-verification-code";
import { CountDown } from "@marathon/web/components/TimerCountDown";
import CallableFunctions from "@marathon/client-side/utilities/CallableFunctions";
import { CustomerVerificationMode, OnlineBookingFlowType } from "@marathon/common/constants";
import steps from "@marathon/common/onlineBookingSteps";
import { TitleWrapper, ContentWrapper, FieldsetWrapper } from "./shared/CommonWrappers";
import { shouldBeUnreachable } from "@marathon/common/typesHelper";

interface Props {
    flowType: OnlineBookingFlowType,
    onNext: () => void,
    phoneNumber: string,
    initialEmail?: string
}

export default function OnlineBookingCodeVerification({ flowType, onNext, phoneNumber, initialEmail }: Props) {
    const [isSingingIn, setIsSingingIn] = useState(false);
    const [isResending, setIsResending] = useState(false);
    const [isChangingMode, setIsChangingMode] = useState(false);
    const [mode, setMode] = useState(CustomerVerificationMode.phoneCode);
    const [code, setCode] = useState<string>("");
    const [isConfirming, setIsConfirming] = useState(false);
    const [showCodeError, setShowCodeError] = useState(false);
    const [isResendDisabled, setIsResendDisabled] = useState(false);
    const [email, setEmail] = useState<string>(initialEmail ?? "");
    const { onSignIn, onSendSignInLinkToEmail } = useOnlineBookingContext();
    const digits = 6;
    const classes = useStyles();

    const getSingInLinkUrl = (email: string) => {
        switch (flowType) {
            case OnlineBookingFlowType.newCustomer:
                return `${window.location.origin}/online-booking-signup/${steps.newLead.codeVerification}?email=${email}`;
            case OnlineBookingFlowType.returningCustomer:
                return `${window.location.origin}/online-booking/${steps.returning.phoneInput}/${steps.returning.phoneInputSubsteps.codeVerification}?email=${email}`;
            default:
                shouldBeUnreachable(flowType);
        }
    };

    const toggleCaptchaBadge = (show: boolean) => {
        const badge = document.getElementById("recaptcha-container");
        if (badge && badge instanceof HTMLElement) {
            badge.style.visibility = show ? "visible" : "hidden";
        }
    };

    useEffect(() => {
        toggleCaptchaBadge(true);
        return () => toggleCaptchaBadge(false);
    }, []);

    const onSubmitCode = async () => {
        setIsConfirming(true);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const codeConfirm = (window as any).confirmationResult as ConfirmationResult;
        try {
            const result = await codeConfirm.confirm(code);
            if (result.user.isAnonymous) {
                const credential = PhoneAuthProvider.credential(codeConfirm.verificationId, code);
                linkWithCredential(result.user, credential);
            }
            onNext();
        }
        catch (error) {
            Sentry.captureException(error);
            console.log(error);
            setShowCodeError(true);
        }
        finally {
            setIsConfirming(false);
        }
    };

    const handleResend = async () => {
        setIsResending(true);
        switch (mode) {
            case CustomerVerificationMode.phoneCode:
                await sendPhoneCode();
                break;
            case CustomerVerificationMode.emailLink:
                await sendSignInLinkToEmail();
                break;
            default:
                shouldBeUnreachable(mode);
        }
        setIsResending(false);
    };

    const handleChangeMode = async () => {
        setIsSingingIn(false);
        setIsChangingMode(true);
        switch (mode) {
            case CustomerVerificationMode.phoneCode:
                await sendSignInLinkToEmail();
                setMode(CustomerVerificationMode.emailLink);
                break;
            case CustomerVerificationMode.emailLink:
                await sendPhoneCode();
                setMode(CustomerVerificationMode.phoneCode);
                break;
            default:
                shouldBeUnreachable(mode);
        }
        setIsChangingMode(false);
    };

    const sendPhoneCode = async () => {
        setCode("");
        try {
            await onSignIn({ phoneNumber: normalizePhone(phoneNumber), recaptcha_id: "recaptcha-container-resend" });
            setIsResendDisabled(true);
            setShowCodeError(false);
        }
        catch (error) {
            Sentry.captureException(error);
        }
    };

    const sendSignInLinkToEmail = async () => {
        try {
            let currentEmail: string | null = email;
            if (!currentEmail) {
                const result = await CallableFunctions.current.public.getCustomerEmail({ phone: phoneNumber });
                if (!result) {
                    throw Error("An error has occurred while getting customer's email.");
                }
                currentEmail = result.email;
                setEmail(currentEmail ?? "");
            }
            if (!currentEmail)
                return;
            await onSendSignInLinkToEmail(currentEmail, getSingInLinkUrl(currentEmail));
            setIsResendDisabled(true);
            setShowCodeError(false);
        }
        catch (error) {
            Sentry.captureException(error);
        }
    };

    const blurEmail = (email: string, startCount = 1, endCount = 1) => {
        if (!email) return "";
        const [name, domain] = email.split("@");
        const { length: nameLength } = name;
        const maskedName = nameLength <= startCount + endCount ?
            name
            : name.slice(0, startCount) + "*".repeat(nameLength - startCount - endCount) + name.slice(-endCount);
        const maskedEmail = maskedName + "@" + (domain ?? "");
        return maskedEmail;
    };

    const obfuscatePhoneNumber = (phoneNumber: string) => {
        if (!phoneNumber) return "";
        const { length } = phoneNumber;
        const maskedPhoneNumber = length <= 7
            ? phoneNumber
            : `(${phoneNumber.slice(0, 3)}) ${"*".repeat(length - 7)} ${phoneNumber.slice(-4)}`;
        return maskedPhoneNumber;
    };

    return (
        <OnlineBookingContentWrapper>
            <TitleWrapper
                title="Enter the verification code"
                subtitle={mode === CustomerVerificationMode.phoneCode
                    ? `A code was sent to ${obfuscatePhoneNumber(phoneNumber)}.`
                    : `A sign-in email with additional instructions was sent to ${blurEmail(email)}. Check your email to authenticate your account.`}
            />
            <ContentWrapper>
                <FieldsetWrapper hasError={showCodeError}>
                    <div style={{ fontWeight: 400, fontSize: 17 }}>
                        {isResendDisabled &&
                            <span>
                                Try again in (<CountDown onExpire={() => setIsResendDisabled(false)} startAt={30} />s)
                            </span>}
                        {!isResendDisabled &&
                            <>
                                {(isResending || isChangingMode) && <CircularProgress style={{ marginTop: 20 }} />}
                                {!isResending && !isChangingMode &&
                                    <div style={{ textDecoration: "underline", color: "#0066FF", textAlign: "left" }}>
                                        <span style={{ cursor: "pointer" }} onClick={handleResend}>
                                            {mode === CustomerVerificationMode.phoneCode ? "Resend code" : "Resend email link"}
                                        </span>
                                        {!(flowType === OnlineBookingFlowType.newCustomer && mode === CustomerVerificationMode.emailLink) && !isSingingIn &&
                                            <span style={{ cursor: "pointer", marginLeft: 25 }} onClick={handleChangeMode}>
                                                {mode === CustomerVerificationMode.phoneCode ? "Email instead" : "Phone code instead"}
                                            </span>}
                                    </div>}
                            </>}
                    </div>
                    {mode === CustomerVerificationMode.phoneCode &&
                        <div className="code-verification">
                            {/* HACK: we need the form to prevent browsers from using the autofill */}
                            <form onSubmit={(e) => e.preventDefault()} autoComplete="one-time-code">
                                <ReactInputVerificationCode value={code} length={digits} onChange={setCode} />
                            </form>
                        </div>}
                    {showCodeError &&
                        <FormHelperText style={{ marginTop: 30 }}>
                            {`Whoops! The ${mode === CustomerVerificationMode.emailLink ? "email link" : "code"} is invalid`}
                            <br />
                            If you get stuck, please call or text us at (800) 742-9255
                        </FormHelperText>}
                    {mode === CustomerVerificationMode.phoneCode &&
                        <div>
                            <Button
                                showSpinner={isConfirming}
                                type="submit"
                                variant="contained"
                                className={classes.button}
                                onClick={onSubmitCode}
                                disabled={code?.length < 6 || code.includes("·")}
                                disableElevation>
                                Verify
                            </Button>
                        </div>}
                </FieldsetWrapper>
                <div id="recaptcha-container-resend"></div>
            </ContentWrapper>
        </OnlineBookingContentWrapper>
    );
}