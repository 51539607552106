import { Paths } from "../typeUtils";

interface QueryFilter<T> {
    field: Paths<T>,
    operator: FilterOperator,
    value: unknown
}

enum FilterOperator {
    in = "in",
    greaterThanOrEqual = ">=",
    lessThanOrEqual = "<=",
    greaterThan = ">",
    lessThan = "<",
    equal = "==",
    notEqual = "!="
}

enum OrderDirection {
    asc = "asc",
    desc = "desc"
}

interface OrderBy<T> {
    field: Paths<T>;
    direction: "asc" | "desc";
}

export function getFilter<T>(field: Paths<T>, operator: FilterOperator, value: unknown): QueryFilter<T> {
    return { field, operator, value };
}

export function getOrderBy<T>(field: Paths<T>, direction: "asc" | "desc"): OrderBy<T> {
    return { field, direction };
}

export { FilterOperator, OrderDirection };
export type { QueryFilter, OrderBy };