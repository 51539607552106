import { useEffect } from "react";
import { getLocalizedDateTime, TimeZone } from "@marathon/common/timeZoneHelper";

interface AddToCalendarProp {
    event?: {
        title: string,
        startTime: Date,
        endTime: Date,
        description?: string,
        location: string,
        frequency?: { type: string; interval: number; },
        timeZone: TimeZone
    } | null,
    onBlurCallback?: () => void
}

export const AddToCalendar = ({ event, onBlurCallback = () => null }: AddToCalendarProp) => {

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if ((window as any).addeventasync) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (window as any).addeventasync();
        }
    }, []);

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if ((window as any).addeventatc) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (window as any).addeventatc.refresh();
        }
    });

    const getDateTime = (date: Date, timeZone: TimeZone) => {
        return getLocalizedDateTime(date, timeZone).toFormat("yyyy-MM-dd HH:mm");
    };

    return (
        <>
            {event &&
                <div
                    title="Add to your Calendar"
                    onBlurCapture={onBlurCallback}
                    className="addeventatc"
                    style={{ width: "100%", marginTop: 10, textAlign: "center" }}
                    data-dropdown-y="down"
                    data-intel="false">
                    <span className="icon"></span>
                    Add to your Calendar
                    <span className="start">{getDateTime(event.startTime, event.timeZone)}</span>
                    <span className="end">{getDateTime(event.endTime, event.timeZone)}</span>
                    <span className="timezone">{event.timeZone}</span>
                    <span className="title">{event.title}</span>
                    <span className="description">{event.description}</span>
                    <span className="location">{event.location}</span>
                    {event.frequency &&
                        <span className="recurring">FREQ={event.frequency.type};INTERVAL={event.frequency.interval}</span>}
                </div>
            }
        </>
    );
};