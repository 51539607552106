import { PricingCopyBuilder } from "../pricingCopyBuilder";
import { PricingPreset } from "./PricingPreset";
import { Service } from "./Service";

interface BreedData {
    name: string,
    description: string,
    photo: string,
    coat_length: number,
    weight_services: WeightService[]
}

interface WeightService {
    weight_from: number,
    weight_to: number | null,
    service_ids: string[]
}

enum CoatTypes {
    LongHairAndCurlyHair = "Long Hair and Curly Hair",
    ShortHair = "Short Hair",
    DoubleCoat = "Double Coat"
}

interface Breed extends BreedData {
    id: string
}

class Breed {
    constructor(id: string, data: BreedData) {
        this.id = id;
        Object.assign(this, data);
    }
    toData(): BreedData {
        const { id, ...data } = this;
        return data;
    }
    getServiceIdsByWeight(weight: number) {
        const roundedWeight = Math.round(weight);
        return this.weight_services.find(ws => ws.weight_from <= roundedWeight && (!ws.weight_to || ws.weight_to >= roundedWeight))?.service_ids;
    }
    getMainAcronym(services: Service[]) {
        const defaultService = services.find(x => x.id === this.weight_services[0].service_ids[0]);
        return defaultService?.getAcronym();
    }
    getCoatType(services: Service[], weight: number) {
        const serviceIds = this.getServiceIdsByWeight(weight);
        if (!serviceIds)
            throw new Error("No service ids found");
        const selectedServices = services.filter(x => serviceIds.some(serviceId => serviceId === x.id));
        const acronym = selectedServices[0].getAcronym();
        switch (acronym) {
            case "CR":
            case "LH":
                return CoatTypes.LongHairAndCurlyHair;
            case "SH":
                return CoatTypes.ShortHair;
            case "SD":
            case "LD":
                return CoatTypes.DoubleCoat;
            default:
                throw new Error("No coat type found");
        }
    }
    getPricingTemplate(weight: number, services: Service[], pricingPreset: PricingPreset, dogName?: string) {
        const serviceIds = this.getServiceIdsByWeight(weight);
        if (!serviceIds)
            return null;
        const selectedServices = services.filter(x => serviceIds.some(serviceId => serviceId === x.id));
        const servicesWithPrice = selectedServices.map(x => ({ ...x, price: pricingPreset.services.find(s => s.service_id === x.id)?.price }));
        const coatType = this.getCoatType(selectedServices, weight);
        const builder = new PricingCopyBuilder();
        const serviceDescription = builder
            .setDogName(dogName);

        if (coatType === CoatTypes.LongHairAndCurlyHair) {
            serviceDescription.setLongAndCurlyHairService();
            const prices = servicesWithPrice.sortByFieldDescending(x => x.price).map(x => x.price);
            if (!prices)
                throw new Error("No prices found");
            const fullHaircutPrice = prices[0];
            const partialHaircutPrice = prices[1];
            const bathOnlyPrice = prices[2];
            if (fullHaircutPrice)
                serviceDescription.setFullHaircutOption(fullHaircutPrice);

            if (partialHaircutPrice)
                serviceDescription.setPartialHaircutOption(partialHaircutPrice);

            if (bathOnlyPrice)
                serviceDescription.setBathOnlyOption(bathOnlyPrice);
        }
        else if (coatType === CoatTypes.ShortHair) {
            const price = servicesWithPrice[0].price;
            if (!price)
                throw new Error("No price found");
            serviceDescription.setShortHairService(price);
        }
        else if (coatType === CoatTypes.DoubleCoat) {
            const prices = servicesWithPrice.sortByFieldAscending(x => x.price).map(x => x.price);
            const deshed = prices[0];
            const deshedAndHaircut = prices[1];
            if (!prices)
                throw new Error("No price found");
            if (deshed)
                serviceDescription.setDoubleCoatService(deshed);
            if (deshedAndHaircut)
                serviceDescription.setDoubleCoatAndHaircutService(deshedAndHaircut);
        }

        return builder.build();
    }
    static getMattedDogAcronyms() {
        return ["LD", "LH", "CR"];
    }
    getServiceIds() {
        return this.weight_services.flatMap(x => x.service_ids);
    }
}

export { Breed };
export type { BreedData };